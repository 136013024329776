.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 40px !important;
  transition: all 0.3s;
}

.ant-select-selection-search {
  line-height: 40px !important;
}

.logoContainer {
  margin-left: -5px;
  margin-bottom: 16px;
}

.name-container {
  color: var(--neutral-neutral-100, #14151C);

  /* Semibold / 30px | 38px */
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
  letter-spacing: -0.375px;


  margin-left: 5px;
  margin-bottom: 32px;
}

.message-container {

  .message-container-first {
    color: var(--neutral-neutral-80, #363948);

    /* Regular / 16px | 24px */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.12px;

    margin-left: 5px;
  }

  .message-container-second {
    color: var(--neutral-neutral-80, #363948);

    /* Regular / 16px | 24px */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.12px;

    margin-left: 5px;
    margin-top: 10px;
  }
}

#submit-country-btn {
  background-color: #14803C;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  width: 100%;
  height: 40px;

}

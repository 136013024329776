@import "~antd/dist/antd.less";

@primary-color: #00b5d8;
@layout-body-background: #E5E5E5;

@heading-color: #707374;

@label-color: #707374;

// Input
@input-bg: #f5f8fc;
@input-border-color: #e8eaed;
@input-color: #14151c;

@select-dropdown-bg: #f5f8fc;
@select-background: #f5f8fc;

@border-color-base: @input-border-color;

// Page header
@page-header-back-color: #00A0BE;

// Menu
@menu-bg: #f5f8fc;
@menu-item-active-bg: #FFFFFF;

//tab
@tabs-card-active-color: #749204;
@tabs-card-height: 44px;
@tabs-hover-color: #749204;
@tabs-active-color: #749204;
@tabs-card-head-background: #F0F3F7;

//modal
@modal-footer-border-color-split: #ffffff;
@modal-heading-color: #F74141;

// collapse
@collapse-header-bg: #F2F4F6;

@font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


@btn-default-color: #2F3031;
@btn-default-bg: #F2F4F6;
@btn-default-border: #F0F3F7;

.ant-form-item-required::before {
  content: '';
}

.ant-form-explain {
  float: left;
}

.ant-legacy-form-vertical .ant-legacy-form-item {
  padding-bottom: 8px;
  margin-bottom: 0px;
}

.ant-legacy-form-item-label,
.ant-col-24.ant-legacy-form-item-label {
  padding-bottom: 0px !important
}

@form-item-margin-bottom: 5px;
@form-vertical-label-padding: 0;

.ant-popover-inner-content {
  padding: 0;
}

// Ensure notification margin bottom for components/mfes that use different versions of antd
.ant-notification-notice {
  margin-bottom: 0;
}

html {
  overflow: hidden;
}

body {
  color: #707374;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  cursor: pointer;
  border-radius: 5px;
  background: #c1c5c8;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 3px 2px 6px #9d9d9d;
}

@media screen and (max-width: 1023px) {
  /* ADD YOUR CSS ADJUSTMENTS BELOW HERE */
  // html{
  //   overflow: scroll;
  //   width: 100vw;
  // }

}

@btn-disable-bg: #C1C5C8;
@btn-disable-color: #E8EAED;

.ant-btn-secondary:hover {
  background: #E8EAED;
  color: #2F3031;
  border-color: #F0F3F7;
}

.btn-orange {
  background: #FFFFFF;
  border: 1px solid #F9C43D;
  color: #F9C43D;
}

.btn-orange:hover {
  background: #F9C43D;
  color: #FFFFFF;
  border: 1px solid #F9C43D;
}

.btn-green,
.btn-green:focus {
  background-color: #AAB400;
  border: 1px solid #AAB400;
  color: #FFFFFF;
}

.btn-green:hover {
  background-color: #749204;
  border: 1px solid #749204;
  color: #FFFFFF;
}

.btn-green.disabled,
.btn-green.disabled:hover,
.btn-green.disabled:focus {
  background-color: #C1C5C8;
  border: 0px;
  color: #E8EAED;
  cursor: not-allowed;
}

.btn-green.user-disabled,
.btn-green.user-disabled:hover,
.btn-green.user-disabled:focus {
  background-color: transparent;
  border: 0px;
  color: #E8EAED;
  cursor: not-allowed;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #00A0BE;
  border-color: #00A0BE;
}

.ant-popconfirm {
  .ant-popover-inner-content {
    padding: 16px;
    width: 280px;
    border-radius: 2px;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-message-title {
    padding-bottom: 11px;
  }

  .ant-popover-message-title {
    color: #14151C;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.0075em;
    text-align: left;
    padding-left: 29px;
  }

  .ant-btn-primary {
    background-color: #14803C;
    border-color: #14803C;

    &:hover {
      color: #FFFFFF;
      background-color: #19A04B;
      border-color: #19A04B;
    }
  }

  .ant-btn:not(.ant-btn-primary) {
    background: #FFFFFF;
    border: 1px solid #C2C7D0;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover {
      color: #14151C;
    }
  }
}

#mapId {
  height: calc((100vh - 54px));
  overflow: hidden;
}

.syt-map-container-right {
  .leaflet-control-container>.leaflet-bottom {
    bottom: 46px;
  }

  .leaflet-control-container>.leaflet-right {
    right: -4px;
  }
}

.syt-map-container-left {
  .leaflet-control-container>.leaflet-right {
    bottom: 46px;
    right: -4px;
  }
}

.back-button {
  margin-right: 15px;
  border-radius: 20px;
  color: #00a0be;
  cursor: pointer;
  width: 40px;
  height: 40px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &:hover {
    background-color: #00a0be;
    color: white;
    text-align: center;
  }
}

.disable-back-button {
  margin-right: 15px;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &:hover {
    cursor: not-allowed;
    background-color: transparent;
    color: white;
    text-align: center;
  }

  &.edit-season {
    &:hover {
      cursor: not-allowed;
      background-color: transparent;
      color: #C1C5C8;
      text-align: center;
    }
  }
}

.header-error-boundary {
  padding-left: 13px;
  display: flex;
  flex-direction: column;
  height: 33px;
  justify-content: flex-end;
}

.map-wheather-div {
  position: fixed;
  top: 80px;
  right: 25px;
  min-width: 271px;
  box-sizing: border-box;
  z-index: 0;
  background: #fff;
  border-radius: 8px !important;
}

.map-wheather-menu {
  background: #fff;
  border-radius: 8px;
}

.map-wheather-menu ul,
.map-wheather-div ul {
  border-radius: 8px !important;
}

.map-wheather-menu * {
  background-color: #fff !important;
}

.wheather-submenu {
  border-radius: 8px;
  width: 98%;
  margin: auto;
}

.wheather-submenu .ant-menu-submenu-title span,
.wheather-submenu ul li {
  font-family: Rubik, sans-serif;
}

.wheather-submenu .ant-menu-submenu-title {
  padding-left: 15px !important;
}

.map-wheather-div .ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0;
}

.map-timeline-container-and-weather-responsive {
  width: 700px !important;
  left: 41% !important;
}

.ant-drawer-header {
  padding-left: 16px;
}

#weather-drawer .ant-drawer-wrapper-body {
  height: 90%;
}

.farm-name {
  width: 60%;
}

@media (min-width: 1366px) {
  .allFields-select {
    width: 150px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1365px) and (max-height: 680px) {
  #scollId .ant-list-item {
    align-items: initial;
  }

  .allFields-select {
    width: 120px !important;
  }

  .create-field-options {
    width: 96% !important;
    // height: 150px !important;
  }

  .map-wheather-div {
    min-width: 200px !important;
  }

  .platformuser-search-box {
    width: 270px !important;
  }

  .map-timeline-container {
    width: 750px !important;
    left: 55% !important;
  }

  // .progress-date-container .progress-date{
  //   left: 10% !important;
  // }
  .map-timeline-container-and-weather-responsive {
    width: 530px !important;
    left: 43% !important;
  }

  .map-timeline-container-and-weather-responsive .progress-date-container .progress-date {
    left: 20% !important;
  }

  .map-timeline-container-and-weather-responsive .main-container .harvest-icon {
    margin-left: 4px !important;
    width: 50px;
    height: 50px;
    margin-top: 8px;
  }

  .map-timeline-container-and-weather-responsive .field-details .ant-row .ant-col-16 {
    margin-left: 2px;
  }

  .weather-drawer-class .ant-drawer-content-wrapper {
    width: 55% !important;
  }

  .weather-drawer-class {
    width: 55% !important;
  }
}

.season-fields-date .ant-input:placeholder-shown {
  padding-right: 15px;
}

.modalWrapClass {
  position: absolute;
  overflow: initial;
  top: 54px;
}


.noBoundaryIconDiv:hover .icon-area-black::before {
  color: #FFFFFF;
}

.create-no-boundary-check-button {
  background-color: #FFFFFF;
}

.create-no-boundary-check-button:hover {
  background-color: #AAB400;
}

.create-no-boundary-check-button:hover .icon-check::before {
  color: #FFFFFF;
}

.print-save-page {
  color: #AAB400;
  font-size: 14px;
}

.create-no-boundary-check-button:hover .icon-check::before,
.create-no-boundary-check-button:hover .icon-print::before,
.create-no-boundary-check-button:hover .print-save-page,
.create-no-boundary-check-button:hover .icon-download::before {
  color: #FFFFFF;
}

.bgColorEdit:hover {
  background-color: #aab400 !important;
}

.bgColorEdit.disabledOption:hover {
  background-color: transparent !important;
}

.print-close:hover .icon-close-glyph-2::before {

  color: #00A0BE;
}

.bgColorDelete:hover {
  background-color: #f74141 !important;
}

.bgColorCreateBoundary:hover {
  background-color: #e6ffff !important;
}

.error-tooltip, .white-bg-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      color: #363948;
      background: white;
    }

    .ant-tooltip-arrow-content::before, .ant-tooltip-arrow::before {
      background: white;
    }
  }
}

.arrowless-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-arrow-content::before, .ant-tooltip-arrow::before {
      background: none;
    }
  }
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ffffff00 !important;
  // border-right-width: 0px !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #ffffff00 !important;
}

.ant-input-affix-wrapper:hover .ant-input {
  border-color: #ffffff00 !important;
}

.ant-input:hover,
.ant-input:focus {
  border-color: #ffffff00 !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 0px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #F3F4F6 inset !important;
  background-color: #F3F4F6 !important;
}

input:-internal-autofill-selected,
input:-internal-autofill-selected:hover,
input:-internal-autofill-selected:focus,
input:-internal-autofill-selected:active {
  -webkit-box-shadow: 0 0 0 30px #F3F4F6 inset !important;
  background-color: #F3F4F6 !important;
}

.icon-list::before,
.icon-kanban::before {
  position: relative;
  // top: 5px;
  right: 2px;
}

.menustartchange {
  background-image: url('./public/menu-start-change-inactive.svg') !important;
}

.menustartinactive {
  background-image: url('./public/menu-start-inactive.svg') !important;
}

.menucontinue {
  background-image: url('./public/menu-continue.svg') !important;
}

.menuendinactive {
  background-image: url('./public/menu-end-inactive.svg') !important;
}

.logoiconbackground {
  background-image: url('./public/cropwise-base-logo.svg') !important;
}

.ant-confirm-modal-wrap {
  z-index: 2000;
}

.has-error .ant-input:not([disabled]):hover,
.has-error .ant-input:focus {
  border-color: #f5222d !important;
}

// dynamic logo
.primary_logo_GLOBAL {
  content: url("./public/cropwise-logo.svg");
}

.primary_logo_UK,
.view_details_logo_UK {
  content: url("./public/syngenta_logo.svg");
}

.secondary_logo_GLOBAL,
.view_details_logo_GLOBAL {
  content: url("./public/cropwise-base-logo.svg");
}

.secondary_logo_UK {
  content: url("./public/secondary-logo-uk.svg");
}

.base-logo-with-syngenta-brand {
  content: url("./public/base-logo-with-syngenta-brand.svg");
}

.baseLogoImg {
  content: url("./public/base-icon.svg");
}

.cropwise-logo-power-by-syngenta {
  content: url("./public/cropwise-power-by-syngenta.svg");
}

.leaflet-tooltip {
  opacity: 0.9 !important;
}

.leaflet-draw-tooltip {
  top: 30px;
  left: 30px;
}

.leaflet-control-attribution.leaflet-control {
  margin-right: 24px;
}

.season-dateRange .ant-calendar-picker-clear,
.season-fields-date .ant-calendar-picker-clear,
.historic-date .ant-calendar-picker-clear {
  opacity: 1;
}

.navbar-arrow-down {
  content: url("./public/ArrowDown.svg");
}

.navbar-arrow-up {
  content: url("./public/ArrowUp.svg");
}

.ant-btn-lg {
  font-size: 14px;
}

.popupmenuList>.ant-menu-item-selected {
  background-color: transparent !important;
}

.popupmenuList>.ant-menu-item:active,
.popupmenuList>.ant-menu-item-active {
  background: transparent !important;
}

.popupmenuList>.ant-menu-item .ant-radio-button-wrapper {
  margin-right: 4px;
  border-radius: 4px;
  padding: 0 6px;
  height: 30px;
}

.popupmenuList>.ant-menu-item .ant-radio-button-wrapper:last-child {
  margin-right: 0px !important;
}

.icon-NoProperty {
  content: url("./public/Icon_NoProperty.svg");
}

.icon-NoSeason {
  content: url("./public/Icon_NoSeason.svg");
}

.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 250px;
}

.p-0 {
  padding: 0px !important;
}

.responsive-msg {
  min-height: 60px;
  background: rgb(49 50 62 / 0.9);
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  padding: 5px 40px;
  color: #fff;
  z-index: 11;

  p {
    margin: 0;
  }

  button {
    border: 0;
    color: white;
    padding: 6px;
    margin-left: 10px;
    background: #066e34;
    border-radius: 4px;
    min-width: 120px;
    outline: 0;
  }
}

.no-card-left {
  position: relative;

  .season-img {
    left: -39px;
    width: auto
  }
}

.component-properties-season-card {
  .season-img {
    width: 40vw;
    left: -30px;
  }
}

.eCngSU {
  z-index: 9;
}

#weather-drawer {
  z-index: 0 !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .rightnav-col {
    padding-right: 0px !important;
  }

  .rightMenuUl li {
    margin: 0 !important;
  }

  .create-field-options {
    width: 170px !important;
  }

  .draw-tool {
    max-width: 100% !important;
    flex: 0 0 100% !important;

    button {
      width: 100% !important;
      margin-bottom: 8px;
    }

    &:nth-child(2) {
      button {
        margin-bottom: 0px;
      }
    }
  }

  .ant-page-header,
  .header {
    font-size: 16px !important;
  }

  .map-timeline-container {
    // width: 750px !important;
    // left: 55% !important;
    width: 700px !important;
    left: 52.5% !important;
  }

  .managed-user {
    .user-info {
      width: 100%;
    }

    .properties {
      width: 95%;
    }

    .info-devider {
      display: none;
    }
  }

  .content-box-responsive {
    max-width: 100%;
    display: flex;
    flex: 0 0 100%;

    span.ant-typography {
      width: 40%;
    }

    .info-block {
      width: 60%;

    }

    .profile-info {
      width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
    }

    .content {
      width: 100%;
    }
  }

  .noboundary-btns {
    height: auto !important;
    padding-top: 10px !important;

    .boundary-btn {
      &:nth-child(3) {
        margin-top: 10px;
      }
    }
  }

  .draw-field-btns {
    .field-btn {
      max-width: inherit;

      &:nth-child(3) {
        margin-right: 10px;
      }
    }
  }

  .ant-collapse-item {
    .ant-list-items {
      .ant-list-item {
        flex-direction: column;
        align-items: flex-start;
        position: relative;

        .ant-list-item-meta {
          .ant-list-item-meta-content {
            display: flex;
            width: 100%;

            .ant-list-item-meta-title {
              margin-right: 10px;
            }
          }
        }

        .ant-checkbox-wrapper {
          position: absolute;
          right: 0;
          top: 50%;
        }
      }
    }
  }

  .header-right-icon {
    margin-right: 10px !important;
  }

  .all-fields-list {
    .ant-list-items {
      .ant-list-item {
        border-bottom: 1px solid #f0f3f7;
        // flex-direction: column;
        align-items: flex-start;

        .ant-list-item-meta {
          width: 100%;
          margin-bottom: 10px;
        }

        .all-fields-select {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }

        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }

  // Responsive Header
  .responsive-header {
    .responsive-img {
      max-width: 110px;
    }

    .responsive-nav {
      max-width: calc(100% - 110px);

      .responsive-col {
        max-width: 25% !important;
        flex: 0 0 25%;
      }

      .responsive-col-none {
        display: none;
      }
    }

    .content {
      width: calc(100%);

      .menustartchange,
      .menucontinue,
      .menuendinactive {
        width: 180px !important;
      }
    }
  }

  .component-properties-season-card {
    .no-card-left {
      max-width: 68% !important;
      flex: 0 0 68% !important;

      .gdA-dwp {
        width: 35vw;
        margin: 0 auto;
        position: relative;
        left: 0;
      }
    }

    .no-card-right {
      max-width: 30% !important;

      .no-card-text {
        left: 0em;
        font-size: 16px;
      }
    }
  }

  .ttorh {
    .description-content-box {
      button {
        &.hrdssu {
          margin-bottom: 10px;
        }
      }
    }
  }

  .responsive-forecast {
    justify-content: space-around !important;
  }

  .respnsive-progress {
    justify-content: space-between;
    display: flex;

    .respnsive-progress-date {
      max-width: calc(80% - 100px);
    }

    .responsive-plant-icon {
      margin-right: 30px;
    }

    .responsive-harvest-icon {
      max-width: inherit;
      flex: inherit;
    }
  }

  .platformuser-search-box {
    width: 100% !important;
  }

  .hcqCwM {
    padding-right: 0 !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .all-areas {
    overflow: hidden;
  }
}

@media (max-width: 900px) and (min-width: 800px) {

  .responsive-header .content .menustartchange,
  .responsive-header .content .menucontinue,
  .responsive-header .content .menuendinactive {
    width: 150px !important;
  }
}

@media (max-width: 799px) and (min-width: 768px) {

  .responsive-header .content .menustartchange,
  .responsive-header .content .menucontinue,
  .responsive-header .content .menuendinactive {
    width: 140px !important;
  }
}


@media (max-width: 992px) {
  .no-card-left {
    max-width: 70% !important;
    flex: 0 0 70% !important;

    .season-img {
      left: -39px;
      width: 100%;
    }
  }

  .no-card-right {
    max-width: 30% !important;
  }
}

@media (max-width: 1024px) {
  .component-properties-season-card {
    .no-card-left {
      max-width: 68% !important;
      flex: 0 0 68% !important;

      .property-img,
      .season-img {
        width: 35vw;
        margin: 0 auto;
        position: relative;
        left: 0;

        &.responsive-s-img {
          left: -60px !important;
        }
      }
    }

    .no-card-right {
      max-width: 30% !important;

      .diVCPD {
        left: 0em;
      }
    }
  }

  .table-list {
    // margin-top: 18px !important;
    margin: 16px;
    position: relative;
  }
}

@media (max-width: 767px) {
  .responsive-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

@media (max-width: 1023px) and (max-height: 680px) {
  .kanban-view {
    height: calc(100vh - 260px) !important;
  }
}

/* Portrait */

.ant-table-header table thead .equipment-action,
.ant-table-header table thead .worker-action {
  padding-right: 70px !important;
}

@media (max-width: 800px) {

  .ant-table-header table thead .equipment-action,
  .ant-table-header table thead .worker-action {
    padding-right: 45px;
  }
}

.ant-table-header table thead .equipment-action-pt-BR,
.ant-table-header table thead .worker-action-pt-BR {
  padding-right: 97px !important;
}

@media (max-width: 800px) {

  .ant-table-header table thead .equipment-action-pt-BR,
  .ant-table-header table thead .worker-action-pt-BR {
    padding-right: 74px !important;
  }
}

@media (max-width: 775px) {

  .ant-table-header table thead .equipment-action-pt-BR,
  .ant-table-header table thead .worker-action-pt-BR {
    padding-right: 60px !important;
  }
}

.footer-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
}

.footer-brower-msg {
  @media (max-width: 768px) {
    flex-direction: column;
  }
}


// Hall of Organisations empty css
.my-organizations-empty {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 780px;
  margin: 50px auto auto auto;
  width: 100%;

  .empty-block {
    height: 300px;
    max-height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .ant-empty-image {
      width: 500px;
      height: 160px !important;
      margin-bottom: 0 !important;
    }
  }

  .ant-empty-image {
    img {
      height: auto !important;
      margin: auto !important;
      width: 100%;
    }
  }

  .ant-empty-description .no-organizations {
    position: absolute;
    // right: 37.5%;
    width: 100%;
    left: 0;
    top: 28px;
    color: #00004B;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
  }

  .ant-empty-footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 31.5%;
    bottom: 0px;

    .createNewOrgBtn {

      min-width: 182px;

      &:hover {
        border: 1px solid #e0e2e6
      }
    }
  }

  .noorgcreated {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }

  .createNewOrgBtn {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }

  &.hall-of-properties-empty {
    .ant-empty-footer {
      right: auto;
      width: 100%;

      button {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.hallof-searchbox {
  z-index: 0 !important;
  border: 1px solid #e8eaed !important;
  .ant-input {
    font-size: 16px !important;
  }
  &:hover {
    border: 1px solid #e8eaed !important;
  }
}

.hall-of-properties-search-empty-container {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .hall-of-properties-search-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90%;
  }
}

.h-0 {
  height: 0px !important;
}

.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-inner {
  box-shadow: 0px 4px 12px rgba(112, 115, 116, 0.3);
  border-radius: 12px;
  position: relative;
  bottom: 10px;
}

.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
  display: none;
}

.header-icon {
  margin-right: 20px;

  @media (max-width: 900px) {
    margin-right: 8px;
  }
}

.header-divider {
  @media (max-width: 900px) {
    margin: 0px 4px;
  }
}

.header-divider-new {
  height: 24px;

  @media (max-width: 900px) {
    margin: 0px 4px;
  }
}

.header-deck-icon {
  @media (max-width: 900px) {
    margin-right: 14px;
  }
}

.responsive-msg-low-resolution {
  @media (max-width: 450px) {
    min-height: 74px;
  }
}

@media (min-width: 768px) and (max-width: 1220px) {
  .user-location {
    max-width: 100% !important;
    flex: 0 0 100%;
  }

  .all-areas {
    height: calc(100vh - 57px);

    .areas-list {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .shape-file-list {
      height: calc(100% - 128px);
      overflow: auto;

      .ant-list {
        ul {
          li {
            width: 100%;

            .ant-list-item-meta {
              width: calc(100% - 94px);

              .ant-list-item-meta-content {
                width: calc(100% - 50px);

                .ant-list-item-meta-title {
                  width: 90%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }

    .ant-list-empty-text {
      padding: 15px !important;
    }
  }
}

.ant-legacy-form-item-required::before {
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1 !important;
  content: '' !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  // border-color: white;
  box-shadow: none;
  border: 1px solid #e8eaed !important;
}

/*antd override css*/
.ant-btn {
  border-radius: 4px;
}

.ant-modal-content {
  border-radius: 4px;

  .ant-modal-header {
    border-radius: 4px 4px 0 0 !important;
  }

  .ant-modal-footer {
    border-radius: 0 0 4px 4px !important;
  }
}

.onboarding-carousel {
  z-index: 1200;

  .ant-modal-content {
    border-radius: 8px;
  }

  &.carousel {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          padding: 0;

          .ant-carousel {
            max-height: 450px;
          }
        }
      }
    }
  }
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}

.product-table {
  table {
    thead {
      tr {
        th {
          position: relative;

          .ant-table-filter-column {
            position: relative;

            .ant-table-filter-trigger-container {
              width: 20px;
              position: absolute;
              right: 25%;

              .ant-table-filter-icon {
                position: relative;
                right: -4px;
                top: 40%;
              }
            }
          }
        }
      }
    }
  }
}

.ant-table-content {
  table {
    thead {

      .equipment-action,
      .worker-action {
        padding-right: 52px !important;
      }
    }

    tbody {

      .equipment-action,
      .worker-action {

        .resource-action,
        .worker-action {
          right: 6px !important;
        }
      }
    }
  }
}

.platformuser-search-box {
  .ant-input-affix-wrapper {
    border-right: 1px solid #e8eaed !important;
    border-radius: 4px !important;
  }
}

.ant-tabs-nav {
  .ant-tabs-tab {
    justify-content: center;

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #749204;
      }
    }
  }
}

.all-fields-list {
  .ant-list-item-meta-title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 1366px) {}

.invite-user-table {
  table {

    thead,
    tbody {
      tr {

        th,
        td {
          &:last-child {
            width: 130px;
            text-align: left !important;
          }
        }
      }
    }
  }
}

.list-text-ellipsis {
  .ant-list-items {
    .ant-list-item {
      .ant-list-item-meta {
        .ant-list-item-meta-content {
          .ant-list-item-meta-title {
            width: 230px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @media (min-width: 1023px) and (max-width: 1275px) {
              width: 200px;
            }

            @media (min-width: 768px) and (max-width: 1024px) {
              width: 100px;
            }
          }
        }
      }
    }
  }
}

.syt-antd-menu-vertical>.syt-antd-menu-item,
.syt-antd-menu-vertical-left>.syt-antd-menu-item,
.syt-antd-menu-vertical-right>.syt-antd-menu-item,
.syt-antd-menu-inline>.syt-antd-menu-item,
.syt-antd-menu-vertical>.syt-antd-menu-submenu>.syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-left>.syt-antd-menu-submenu>.syt-antd-menu-submenu-title,
.syt-antd-menu-vertical-right>.syt-antd-menu-submenu>.syt-antd-menu-submenu-title,
.syt-antd-menu-inline>.syt-antd-menu-submenu>.syt-antd-menu-submenu-title {
  line-height: 33px !important;
}

.triggerExpandButton {
  padding-top: -5px;
}

.CropwiseLogo {
  text-align: center;
  color: #fff;
  height: 60px;
  padding-top: 7px;
}

.settings-title {
  .ant-page-header-content {
    padding-top: 0px;
  }
}

.language-submenu .ant-menu-submenu-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  margin: 0px;
  height: 32px !important;
  background: #fff;

  .ant-menu-submenu-arrow {
    display: none;
  }
}

.language-menu-item {
  height: 32px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px !important;
  letter-spacing: -0.0075em;
  color: #14151C !important;

  &:hover {
    background: #F3F4F6;
    color: #14151C;
  }
}

.language-menu-item-en {
  margin-top: 4px !important;
}

.language-menu-item-de {
  margin-bottom: 4px !important;
}

.header-icon-hover {
  width: 32px !important;
  height: 32px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;

  &:hover {
    background: #DFE2E7;
  }
}

.settings-header-icon {
  &:active {
    background: #C2C7D0 !important;
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
    background: #DFE2E7;
  }
}

.header-popover-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
}

.upload-image-with-status-text .ant-upload.ant-upload-select-picture-card {
  display: table-cell;
  padding: 8px;
}

.platformuser-search-box .ant-input-group-addon {
  display: none;
}

.page-container {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  background-color: white;

  &.h-100 {
    height: 100%;
  }

  ::placeholder {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0075em;
    text-align: left;
    color: #696f88;
    opacity: 1;
    /* Firefox */
  }

  .container-col {
    height: calc((100vh - 54px));
  }

  .container-col.with-step-header {
    height: calc((100vh - 111px));
  }

  .page-header {
    cursor: default;
    height: 56px;
    background: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 14px 24px 14px;

    &.page-header-with-stepper {
      padding: 14px 0px 14px;

      .page-header-title {
        padding-left: 25px
      }
    }

    .ant-page-header {
      padding: 0;
    }

    .ant-page-header-content {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      letter-spacing: -0.0075em;
      color: #14151c;
      padding: 0px;
    }

    .ant-page-header-heading-title {
      color: #14151c;
    }
  }

  .page-title {
    &.ant-page-header {
      padding-left: 0px;
      height: 72px;
      align-items: center;
      display: flex;

      .ant-page-header-back-button {
        span {
          display: flex;
        }
      }
    }

    .ant-page-header-heading-title {
      color: #14151c;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.0075em;
      text-align: left;
    }

    &.with-custom-element {
      .ant-page-header-back-button {
        display: inline-flex !important;
      }

      .custom-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.0075em;
      }

      .info-icon {
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .content-container {
    // height: calc(100% - 56px); // without step header
    height: 100%;
  }

  .input,
  .search {

    .ant-input,
    &.ant-input,
    &.ant-input-affix-wrapper,
    .ant-select-selector {
      color: #14151c;
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.0075em;
      text-align: left;
      background: #f3f4f6;
      border: 1px solid #c2c7d0;
      box-sizing: border-box;
      border-radius: 4px;

      input {
        border: none;
        background: #f3f4f6;
      }

      &:hover,
      &:focus,
      &:focus-within {
        background: white;
        border: 1px solid #0092e4 !important;
        box-shadow: 0px 0px 0px 2px rgba(0, 146, 228, 0.25) !important;

        input {
          background: white;
          border: none !important;
          box-shadow: none !important;
        }
      }
    }

    .has-error .ant-input,
    .has-error .ant-input:hover,
    .has-error .ant-select-selector,
    .has-error .ant-select-selector:hover,
    &.ant-form-item-has-error .ant-input,
    &.ant-form-item-has-error .ant-input-affix-wrapper,
    &.ant-form-item-has-error .ant-input:hover,
    &.ant-form-item-has-error .ant-input-affix-wrapper:hover {
      box-shadow: none !important;
      border: 1px solid #ff4d4f !important;
    }

    .ant-input-disabled,
    &.ant-input-affix-wrapper-disabled,
    .ant-select-selector:disabled {
      background: #C2C7D0;
      border: none !important;
      box-shadow: 1px solid #C2C7D0 !important;

      input {
        background: #C2C7D0;
        border: none !important;
        box-shadow: none !important;
      }

      &:hover,
      &:focus,
      &:focus-within {
        background: #C2C7D0;
        border: none !important;
        box-shadow: none !important;

        input {
          background: #C2C7D0;
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }

  //button classes
  .syt-antd-btn {

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .syt-antd-btn-lg {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.0075em;
    color: #FFFFFF;
  }

  .syt-antd-btn:disabled {
    background: #F3F4F6;
    border: 1px solid #DFE2E7;
    box-sizing: border-box;
    color: #C2C7D0;
  }

  .no-bg-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #14151c;
    border: none;
    background-color: transparent;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      background: #dfe2e7;
      color: #14151c;
    }

    &.disabled {
      background: #F3F4F6;
      border: 1px solid #DFE2E7;
      box-sizing: border-box;
      color: #C2C7D0;
    }
  }

  .primary-bg-button {
    background-color: #14803C;
    border-color: #14803C;
    &:hover {
      background-color: #19A04B;
      border-color: #19A04B;
    }
  }

  .syt-antd-btn-disabled {
    color: #c1c5c8;

    &:hover {
      background: #F3F4F6;
      border: 1px solid #DFE2E7;
      box-sizing: border-box;
    }
  }

  .btn-orange {
    background: #FFFFFF;
    border: 1px solid #F9C43D;
    color: #F9C43D;

    &:hover {
      background: #f9c43d;
      color: #fff;
      border: 1px solid #f9c43d;
    }
  }

  .drop-down-btn {

    .ant-btn-group,
    .ant-dropdown-button {
      height: 40px;

      button {
        font-family: Noto Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #C2C7D0;
        height: 100%;
        z-index: 0;
      }

      button:first-of-type {
        border-radius: 4px 0px 0px 4px !important;
      }

      button:last-of-type {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        border-radius: 0px 4px 4px 0px !important;
      }

      .ant-btn-default:not([disabled]),
      .ant-btn-primary:not([disabled]) {
        border: 0px;
        background-color: #14803C;
        border-color: #14803C;
        border-radius: 4px;
        color: #FFFFFF;

        &:last-of-type {
          .anticon-down {
            transform: rotate(360deg);
            transition: linear 50ms;
          }

          &:hover,
          &:active,
          &:visited {
            background: #19A04B;

            .anticon-down {
              transform: rotate(180deg);
            }
          }
        }
      }

      .ant-btn-default[disabled],
      .ant-btn-primary[disabled] {
        background-color: #F3F4F6;
        border: 1px solid #DFE2E7;
      }

      &.loading {
        background: #19A04B;
        pointer-events: none;
      }
    }
  }

  .ant-input-group-addon {
    color: #696F88;
    background: rgb(232, 234, 237);
  }

  //Form Utility classes
  .form-container {
    .ant-form-item {
      .ant-form-item-label {
        label {
          font-family: 'Noto Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          letter-spacing: -0.0075em;
          color: #696F88;
        }
      }

      .ant-form-item-explain,
      .ant-form-item-explain-error {
        margin-top: 0px;
      }
    }

    .form-item-title {
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.0075em;
      text-align: left;
      color: #14151C;
    }
  }

  .steps {
    &.with-header-title {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }

    .syt-antd-steps .syt-antd-steps-item:not(.syt-antd-steps-item-active)>.syt-antd-steps-item-container[role='button'] {
      pointer-events: none;
    }

    .syt-antd-steps-item:not(.syt-antd-steps-item-process):not(.syt-antd-steps-item-process)>.syt-antd-steps-item-container[role="button"] {
      pointer-events: none;

      .syt-antd-steps-item-title::after {
        pointer-events: none;
      }

      .syt-antd-steps-item-icon,
      .syt-antd-steps-item-title {
        pointer-events: all;
      }

      &:hover {

        .syt-antd-steps-item-icon,
        .syt-antd-steps-item-title {
          border-color: #0071cd;
          color: #0071cd;
        }
      }
    }

    .syt-antd-steps-item:not(.syt-antd-steps-item-active):not(.syt-antd-steps-item-active)>.syt-antd-steps-item-container[role="button"] {
      pointer-events: none;

      .syt-antd-steps-item-title::after {
        pointer-events: none;
      }

      .syt-antd-steps-item-icon,
      .syt-antd-steps-item-title {
        pointer-events: all;
      }

      &:hover {

        .syt-antd-steps-item-icon,
        .syt-antd-steps-item-title {
          border-color: #0071cd;
          color: #0071cd;
        }
      }
    }

    &.syt-antd-steps:not(.syt-antd-steps-dot):not(.syt-antd-steps-navigation):not(.syt-antd-steps-vertical) .syt-antd-steps-item {
      padding-top: 0px;
    }

    .syt-antd-steps-item,
    .syt-antd-steps-item-active {
      .syt-antd-steps-item-icon {
        background: #ffffff;
        border: 1px solid #a3a9b9;
        box-sizing: border-box;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.0075em;

        .syt-antd-steps-icon {
          color: #a3a9b9;
        }
      }

      .syt-antd-steps-item-title {
        color: #696f88;

        &::after {
          background-color: #868ca2;
        }
      }

      &.syt-antd-steps-item-process {
        .syt-antd-steps-item-icon {
          background: #0071cd;
          border: 1px solid #0071cd;
        }

        .syt-antd-steps-icon {
          color: #ffffff;
        }

        .syt-antd-steps-item-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #14151c;
        }
      }

      &.syt-antd-steps-item-finish {
        .syt-antd-steps-item-title {
          &::after {
            background-color: #0071cd;
          }
        }

        .syt-antd-steps-item-icon {
          line-height: 24px;
          border: 1px solid #0071cd;

          .syt-antd-steps-finish-icon {
            color: #0071cd;
          }

          &:hover {
            border: 1px solid #0071cd;
          }
        }

        &:hover {
          .syt-antd-steps-item-title {
            color: #0071cd;
          }
        }
      }
    }
  }

  .ant-dropdown-menu-item.add-another-button {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0075em;
    text-align: left;
    color: #14151C;
  }
}

//common utilities
.tooltip {
  .ant-tooltip-content {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0075em;
    background: #232630;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    .ant-tooltip-arrow {
      right: 0px;
    }

    .ant-tooltip-inner {
      background: #232630;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
  }

  &.show-by-region {
    min-width: 300px;
  }

  &.max-width-400 {
    max-width: 400px;
  }
}

.disabled-element-tooltip-container {
  position: relative;
  margin-top: 15px;
  .disabled-element-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

//Flex utility classes
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-end {
  justify-content: flex-end;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;

  &-justify-center {
    display: flex;
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }
}

//margin utility classes
.m-0 {
  margin: 0px !important;
}

.custom-cursor .syt-dragger>span {
  height: 146px;
  width: 100%;
}

.syt-dragger {
  .ant-upload.ant-upload-drag {
    background: #F3F4F6;
    height: 146px;
  }

  .ant-upload-drag {
    border: 1px dashed #e8eaed !important;
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #c2c7d0 !important;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 0px;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 12px 0;
  }
}


.dragging-message {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px;
  color: #14151c !important;
  letter-spacing: -0.0075em;
}

.supported-files-name {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0075em;
  color: #696F88;
}

.select {
  .rc-virtual-list {
    .rc-virtual-list-holder-inner {
      background-color: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #f3f4f6;
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #eaf6ff;
      }
    }
  }
}

.loader {
  border: 4px solid #DFE2E7;
  border-radius: 50%;
  border-top: 4px solid #14803C;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
  // position: absolute;
  // right: 30%;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  z-index: 9999999;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.mask {
  background: #14151C;
  opacity: 0.5;
  right: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999999;
}

.hide {
  display: none !important;
}

.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.no-bg-btn-with-border {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #14151C;
  background: #FFFFFF;
  border: 1px solid #C2C7D0;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    color: #14151C;
    background: #FFFFFF;
    border: 1px solid #C2C7D0;
  }
}

.warning-modal {
  .ant-modal-confirm-title {
    font-family: "Noto Sans", sans-serif;
    color: #14151C;
    font-style: normal;
    letter-spacing: -0.0075em;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-modal-confirm-content {
    font-family: "Noto Sans", sans-serif;
    color: #14151C;
    font-style: normal;
    letter-spacing: -0.0075em;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  &.desc-spacing {
    .ant-modal-confirm-content {
      margin-left: 0px !important;
    }

    button {
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.syt-option {
  pointer-events: none;

  .ant-select-item-option-state {
    display: none;
  }
}

.delete-button:hover {
  background: #F3F4F6 !important;
}


.delete-modal-confirm {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #14151C;

  .delete-confirm-title {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
  }

  .delete-confirm-disclaimer {
    font-weight: 800;
    margin-top: 1rem;
  }

  .ant-modal-content .ant-modal-body {
    padding: 0px !important;
    padding-bottom: 24px !important;

    .delete-confirm-title {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .ant-modal-footer {
    .ant-btn {
      background: #FFFFFF;
      border: 1px solid #C2C7D0;
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
    }

    .ant-btn:hover {
      color: #14151C;
    }

    .ant-btn-dangerous:hover {
      color: #FFFFFF;
    }

    .ant-btn-dangerous {
      background: #CF3537;
      border: 1px solid #CF3537;
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      color: #FFFFFF;
    }
  }

  .ant-modal-content {
    padding: 24px !important;
  }

  .ant-modal-footer,
  .ant-modal-body,
  .ant-modal-header {
    padding: 0px;
  }

  .delete-modal-confirm-content {
    width: 100%;

    .left {
      float: left;
      width: 10%;
    }

    .right {
      float: right;
      width: 90%;
      margin-bottom: 24px;
    }
  }

}

//cursor utilities
.cursor-pointer {
  cursor: pointer !important;
  // pointer-events: all !important;
}

.cursor-default {
  cursor: default !important;
}

.custom-cursor {
  cursor: url('./public/black-cursor.svg'), auto;
}

.associate-crop-filter-container,
.checkbox {
  .ant-checkbox-wrapper-checked {
    background-color: #EAF6FF !important;
    border-color: #EAF6FF !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0071CD;
    border-color: #0071CD;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #0071CD !important;
  }

  .ant-checkbox-wrapper.ant-checkbox-group-item:hover,
  .ant-checkbox-wrapper.all-crop-cycles-checkbox:hover {
    background: #f3f4f6 !important;
  }

  .ant-checkbox-wrapper.all-crop-cycles-checkbox,
  .ant-checkbox-wrapper.ant-checkbox-group-item {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.0075em;
    color: #14151C;
  }

  .ant-checkbox-wrapper.all-crop-cycles-checkbox {
    font-weight: 600;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #0071CD;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #0071CD;
  }

  .ant-table-cell .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #0071CD;
  }

  .associate-crop-field {
    border-bottom: none !important;
  }
}

// .org-image.height-auto > img {
//   height: auto!important;
// }

.hide-immediate-element+* {
  display: none !important;
}

.hide-element {
  display: none;
}

.btn-default {
  background: #FFFFFF;
  border: 1px solid #C2C7D0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #14151C;
}

.new-tooltip .ant-tooltip-inner {
  background: #232630;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
}

.reverse-ellipsis-dir-rtl {
  .ant-list-item-meta-title {
    direction: rtl;
  }
}

.infoIconWrapper .icon-info-blue:hover::before {
  /**color: #363948;*/
  // Icon is Disabled
}

.page-info-pop-over-container {
  .ant-popover-inner {
    border-radius: 4px;
  }

  .ant-popover-arrow {
    visibility: hidden;
  }

  .page-info-popover {
    color: #14151C;
    width: 348px;
    padding: 16px 24px;
    box-sizing: border-box;

    .title {
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.0075em;
    }

    .image {
      margin: 12px 0;
      background: #E8EAED;
      width: 100%;
      object-fit: contain;
    }

    .content {
      font-weight: normal;
      color: #14151C;
    }

    .bold-span {
      font-weight: 600;
      color: #14151C;
    }
  }
}

//Sidebar CSS
// .sidebar-container {
//   li {
//     display: flex;
//     align-items: center;
//     div {
//       display: flex;
//     }
//   }
// }

.navbar-header-menus-popover {
  padding-top: 0px;

  .antd-popover-open {
    background: #EAF6FF;
    border-radius: 4px;
  }

  .ant-divider {
    border-top: 1px solid #DFE2E7;
  }

  .ant-popover-inner {
    border-radius: 4px;
  }
}

.navbar-tooltip {
  .antd-tooltip-inner {
    background: #232630;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
  }
}

//Header with info icon
.header-with-info {
  padding: 16px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 27%;

  .title {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    letter-spacing: -0.0075em;
    color: #14151C;
    padding-right: 18px;
    min-width: 200px;
  }

  .icon-left {
    cursor: pointer;
    margin-right: 24px;
    font-size: 11px;
    display: inline-flex;
  }

  .icon-left.crop-rotation-back-btn {
    margin-right: 12px;
  }

  .anticon.anticon-info-circle {
    margin: 0px;
  }

  &.table-view {
    padding: 0px 0px 0px 10px;

    .title {
      padding-right: 0px;
    }

    .anticon.anticon-info-circle {
      margin: 0px 14px;
    }
  }
}

.page-info-popup .ant-popover-arrow {
  display: none;
}

.page-info-popup {

  &.ant-popover-placement-right,
  &.ant-popover-placement-rightTop,
  &.ant-popover-placement-rightBottom {
    padding-top: 23px;
    padding-left: 0px;
  }
}

.no-border {
  border: none;
  box-shadow: none;
}

.item-title::first-letter {
  text-transform: capitalize;
}

.show-by-regions-toggle-btn-all-fields {
  .showByRegionsBtnDiv {
    margin: 18px 0px 14px !important;
  }
}

.org-permissions-details {
  color: #4D5165;
  font-size: 14px;
  margin-bottom: 14px;
}

.invite-permissions-container {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  border-radius: 16px;
  width: 838px;
  min-width: 420px;
  padding: 24px 24px;
  height: 263px;
  margin: 4px 4px;
}

.table-container {
  margin-top: 20px;
  height: fit-content;
}

.table-container .ant-table-wrapper {
  margin-right: 25px;
}

.success-notification-action-button-container {
  button {
    width: 72px;
    height: 32px;
    margin-right: 8px;
  }
}

.locate-property-on-map-btn {
  position: absolute;
  z-index: 999;
  bottom: 40px;
  right: 12px;
  display: flex;
  background: rgba(20, 21, 28, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 8px;
  cursor: pointer;
  &:hover {
      background: #232630;
  }
  &:focus, &:active {
      border: 2px solid #00b5d8;
      background: rgba(20, 21, 28, 0.5);
  }
}

.cw-submenu-popup {
  .ant-menu-item-selected::before {
    display: none !important;
  }
}

.ant-drawer-right {
  left: auto !important
}

// In the case we are using button but do not want its button styling and make it to look like as a div
.remove-button-style {
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  font: inherit;
  text-align: inherit;
  cursor: pointer;
}

// Fix Antd 5 override cw elements
.ant-avatar-string {
  position: relative;    
  left: auto;
  transform-origin: initial;
}

.ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-input-number-group-addon {
  min-width: 85px;
  padding: 0;

  .ant-select  {
    margin: -2px -11px;
  }
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1fiuhc');
  src:  url('fonts/icomoon.eot?1fiuhc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?1fiuhc') format('woff2'),
    url('fonts/icomoon.ttf?1fiuhc') format('truetype'),
    url('fonts/icomoon.woff?1fiuhc') format('woff'),
    url('fonts/icomoon.svg?1fiuhc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-resolve-field-icon:before {
  content: "\eb8f";
  color: #696f88;
}
.icon-field-review-filter-icon:before {
  content: "\eb8e";
  color: #696f88;
}
.icon-map-tools-close-icon:before {
  content: "\eb8d";
  color: #a3a9b9;
}
.icon-map_tools_coordinates_icon:before {
  content: "\eb88";
  color: #dfe2e7;
}
.icon-map_tools_dark_mode_icon:before {
  content: "\eb89";
  color: #a3a9b9;
}
.icon-map_tools_field_names_icon:before {
  content: "\eb8a";
  color: #a3a9b9;
}
.icon-map-tools-measures-icon:before {
  content: "\eb8b";
  color: #dfe2e7;
}
.icon-map_tools_roadname_icon:before {
  content: "\eb8c";
  color: #a3a9b9;
}
.icon-property_focus:before {
  content: "\eb87";
  color: #fff;
}
.icon-field-icon-selected-fields-info:before {
  content: "\eb85";
  color: #0071cd;
}
.icon-people:before {
  content: "\eb63";
  color: #696f88;
}
.icon-export-csv:before {
  content: "\eb81";
  color: #696f88;
}
.icon-arrow_forward:before {
  content: "\eb80";
  color: #0071cd;
}
.icon-timeline:before {
  content: "\eb7f";
  color: #696f88;
}
.icon-star_24px:before {
  content: "\e952";
  color: #c2c7d0;
}
.icon-pencil:before {
  content: "\eb5f";
}
.icon-bulb_info:before {
  content: "\eb7e";
  color: #696f88;
}
.icon-warning-triangle:before {
  content: "\eb7c";
  color: #cf3537;
}
.icon-edit-crop-cycle:before {
  content: "\eb5d";
  color: #696f88;
}
.icon-drawing-rectangle-add-without-bg1:before {
  content: "\eb72";
  color: #696f88;
}
.icon-drawing-rectangle-add-without-bg:before {
  content: "\eb73";
  color: #696f88;
}
.icon-keyboard-without-bg:before {
  content: "\eb74";
  color: #696f88;
}
.icon-undo-without-bg:before {
  content: "\eb75";
  color: #696f88;
}
.icon-delete-without-bg:before {
  content: "\eb76";
  color: #696f88;
}
.icon-detection-without-bg:before {
  content: "\eb77";
  color: #696f88;
}
.icon-drawing-circle-subtract-without-bg:before {
  content: "\eb78";
  color: #696f88;
}
.icon-drawing-rectangle-subtract-without-bg:before {
  content: "\eb79";
  color: #696f88;
}
.icon-drawing-circle-add-without-bg:before {
  content: "\eb7a";
  color: #696f88;
}
.icon-filter:before {
  content: "\eb5c";
  color: #696f88;
}
.icon-tractor-icon:before {
  content: "\eb84";
  color: #696f88;
}
.icon-no_boundary:before {
  content: "\eb82";
  color: #696f88;
}
.icon-drive_folder_upload:before {
  content: "\eb83";
  color: #696f88;
}
.icon-attach_file:before {
  content: "\eb3d";
  color: #696f88;
}
.icon-menu:before {
  content: "\eb3b";
  color: #696f88;
}
.icon-menu_open:before {
  content: "\eb3c";
  color: #696f88;
}
.icon-error_outline:before {
  content: "\eb7b";
  color: #cf3537;
}
.icon-aspargo:before {
  content: "\eb3a";
  color: #fff;
}
.icon-oilseed_rape:before {
  content: "\eb39";
  color: #fff;
}
.icon-delete-red:before {
  content: "\eb61";
  color: #cf3537;
}
.icon-vpn_key:before {
  content: "\eb62";
  color: #696f88;
}
.icon-left-arrow:before {
  content: "\eb5e";
  color: #696f88;
}
.icon-Group_close .path1:before {
  content: "\eb65";
  color: rgb(255, 255, 255);
}
.icon-Group_close .path2:before {
  content: "\eb66";
  margin-left: -1.6318359375em;
  color: rgb(112, 115, 116);
}
.icon-Group_close .path3:before {
  content: "\eb67";
  margin-left: -1.6318359375em;
  color: rgb(112, 115, 116);
}
.icon-Group_close .path4:before {
  content: "\eb68";
  margin-left: -1.6318359375em;
  color: rgb(112, 115, 116);
}
.icon-Group_close .path5:before {
  content: "\eb69";
  margin-left: -1.6318359375em;
  color: rgb(112, 115, 116);
}
.icon-calendar-white:before {
  content: "\eb86";
  color: #fff;
}
.icon-zoom-out:before {
  content: "\eb6f";
  color: #c2c7d0;
}
.icon-zoom-in:before {
  content: "\eb70";
  color: #696f88;
}
.icon-calender:before {
  content: "\eb71";
  color: #696f88;
}
.icon-close-glyph1:before {
  content: "\eb7d";
  color: #515253;
}
.icon-edit-crop:before {
  content: "\eb60";
  color: #696f88;
}
.icon-field_detection:before {
  content: "\eb03";
  color: #696f88;
}
.icon-crop_cycle:before {
  content: "\eb6b";
  color: #696f88;
}
.icon-edit_field:before {
  content: "\eb6d";
  color: #696f88;
}
.icon-timeline1:before {
  content: "\eb6e";
  color: #696f88;
}
.icon-farm:before {
  content: "\eb6a";
  color: #fff;
}
.icon-bulb-outlined:before {
  content: "\eb64";
  color: #696f88;
}
.icon-info-blue:before {
  content: "\eb5a";
  color: #0092e4;
}
.icon-info_24px:before {
  content: "\eb5b";
  color: #0092e4;
}
.icon-undo-button .path1:before {
  content: "\eb4a";
  color: rgb(255, 255, 255);
}
.icon-undo-button .path2:before {
  content: "\eb4b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-undo-button .path3:before {
  content: "\eb4c";
  margin-left: -1em;
  color: rgb(105, 111, 136);
}
.icon-property-location .path1:before {
  content: "\eb4d";
  color: rgb(255, 255, 255);
}
.icon-property-location .path2:before {
  content: "\eb4e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-property-location .path3:before {
  content: "\eb4f";
  margin-left: -1em;
  color: rgb(105, 111, 136);
}
.icon-locate-my-add-button .path1:before {
  content: "\eb50";
  color: rgb(255, 255, 255);
}
.icon-locate-my-add-button .path2:before {
  content: "\eb51";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-locate-my-add-button .path3:before {
  content: "\eb52";
  margin-left: -1em;
  color: rgb(105, 111, 136);
}
.icon-locate-my-add-button .path4:before {
  content: "\eb53";
  margin-left: -1em;
  color: rgb(105, 111, 136);
}
.icon-delete-button .path1:before {
  content: "\eb54";
  color: rgb(255, 255, 255);
}
.icon-delete-button .path2:before {
  content: "\eb55";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-delete-button .path3:before {
  content: "\eb56";
  margin-left: -1em;
  color: rgb(105, 111, 136);
}
.icon-upload-button .path1:before {
  content: "\eb57";
  color: rgb(255, 255, 255);
}
.icon-upload-button .path2:before {
  content: "\eb58";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-upload-button .path3:before {
  content: "\eb59";
  margin-left: -1em;
  color: rgb(105, 111, 136);
}
.icon-subtract-polygon .path1:before {
  content: "\eb3e";
  color: rgb(255, 255, 255);
}
.icon-subtract-polygon .path2:before {
  content: "\eb3f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-subtract-polygon .path3:before {
  content: "\eb40";
  margin-left: -1em;
  color: rgb(207, 53, 55);
}
.icon-subtract-polygon .path4:before {
  content: "\eb41";
  margin-left: -1em;
  color: rgb(207, 53, 55);
}
.icon-draw-circle .path1:before {
  content: "\eb42";
  color: rgb(255, 255, 255);
}
.icon-draw-circle .path2:before {
  content: "\eb43";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-draw-circle .path3:before {
  content: "\eb44";
  margin-left: -1em;
  color: rgb(20, 128, 60);
}
.icon-draw-circle .path4:before {
  content: "\eb45";
  margin-left: -1em;
  color: rgb(20, 128, 60);
}
.icon-draw-polygon .path1:before {
  content: "\eb46";
  color: rgb(255, 255, 255);
}
.icon-draw-polygon .path2:before {
  content: "\eb47";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-draw-polygon .path3:before {
  content: "\eb48";
  margin-left: -1em;
  color: rgb(20, 128, 60);
}
.icon-draw-polygon .path4:before {
  content: "\eb49";
  margin-left: -1em;
  color: rgb(20, 128, 60);
}
.icon-new-settings:before {
  content: "\eb38";
  color: #707374;
}
.icon-tractor1:before {
  content: "\eb37";
}
.icon-alert-triangle1:before {
  content: "\eb17";
  color: #fff;
}
.icon-email-glyph1:before {
  content: "\eb35";
  color: #fff;
}
.icon-Group-341:before {
  content: "\eb36";
  color: #aab400;
}
.icon-phenology-1:before {
  content: "\eb2f";
  color: #c1c5c8;
}
.icon-phenology:before {
  content: "\eb2e";
  color: #c1c5c8;
}
.icon-pin-map:before {
  content: "\eb2a";
  color: #fff;
}
.icon-calendar-glyph:before {
  content: "\eb28";
  color: #515253;
}
.icon-Value-Copy:before {
  content: "\eb27";
  color: #707374;
}
.icon-precipitation:before {
  content: "\eb23";
}
.icon-protector .path1:before {
  content: "\eb10";
  color: rgb(170, 178, 18);
}
.icon-protector .path2:before {
  content: "\eb11";
  margin-left: -0.9228515625em;
  color: rgb(200, 215, 45);
}
.icon-protector .path3:before {
  content: "\eb12";
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.icon-protector .path4:before {
  content: "\eb13";
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.icon-wind-direction-path:before {
  content: "\eb0b";
  color: #00a0be;
}
.icon-Path:before {
  content: "\eb0a";
  color: #00a0be;
}
.icon-close-glyph-2:before {
  content: "\eb18";
}
.icon-chevron-right-glyph:before {
  content: "\eace";
}
.icon-Ellipse-17:before {
  content: "\eacd";
  color: #e8eaed;
}
.icon-download1:before {
  content: "\eb30";
  color: #198746;
}
.icon-lamp:before {
  content: "\eb29";
}
.icon-executor:before {
  content: "\eb24";
}
.icon-tag-2:before {
  content: "\eb25";
}
.icon-tractor:before {
  content: "\eb26";
}
.icon-Group_657 .path1:before {
  content: "\eb21";
  color: rgb(142, 146, 147);
}
.icon-Group_657 .path2:before {
  content: "\eb22";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-task-harvest:before {
  content: "\eac6";
  color: #f9c43d;
}
.icon-task-irrigation:before {
  content: "\eb1a";
  color: #f9c43d;
}
.icon-task-sampling:before {
  content: "\eb1b";
  color: #f9c43d;
}
.icon-task-machinery:before {
  content: "\eb1c";
  color: #f9c43d;
}
.icon-task-planting:before {
  content: "\eb1d";
  color: #f9c43d;
}
.icon-task-others:before {
  content: "\eb1e";
  color: #f9c43d;
}
.icon-task-scouting:before {
  content: "\eb1f";
  color: #f9c43d;
}
.icon-task-spray:before {
  content: "\eb20";
  color: #f9c43d;
}
.icon-flask:before {
  content: "\eb14";
  color: #c1c5c8;
}
.icon-sample:before {
  content: "\eb15";
  color: #c1c5c8;
}
.icon-representativeness:before {
  content: "\eb16";
  color: #c1c5c8;
}
.icon-list:before {
  content: "\eb0c";
}
.icon-kanban:before {
  content: "\eb0d";
}
.icon-weather-condition-hourly-1:before {
  content: "\eae7";
}
.icon-weather-condition-hourly-2:before {
  content: "\eae8";
}
.icon-weather-condition-hourly-3:before {
  content: "\eae9";
}
.icon-weather-condition-hourly-4:before {
  content: "\eaea";
}
.icon-weather-condition-hourly-5:before {
  content: "\eaeb";
}
.icon-weather-condition-hourly-6:before {
  content: "\eaec";
}
.icon-weather-condition-hourly-7:before {
  content: "\eaed";
}
.icon-weather-condition-hourly-8:before {
  content: "\eaee";
}
.icon-weather-condition-hourly-9:before {
  content: "\eaef";
}
.icon-weather-condition-hourly-10:before {
  content: "\eaf0";
}
.icon-weather-condition-hourly-11:before {
  content: "\eaf1";
}
.icon-weather-condition-hourly-12:before {
  content: "\eaf2";
}
.icon-weather-condition-hourly-13:before {
  content: "\eaf3";
}
.icon-weather-condition-hourly-14:before {
  content: "\eaf4";
}
.icon-weather-condition-hourly-15:before {
  content: "\eaf5";
}
.icon-weather-condition-hourly-16:before {
  content: "\eaf6";
}
.icon-weather-condition-hourly-17:before {
  content: "\eaf7";
}
.icon-weather-condition-hourly-18:before {
  content: "\eaf8";
}
.icon-weather-condition-hourly-19:before {
  content: "\eaf9";
}
.icon-weather-condition-hourly-20:before {
  content: "\eafa";
}
.icon-weather-condition-hourly-21:before {
  content: "\eafb";
}
.icon-weather-condition-hourly-22:before {
  content: "\eafc";
}
.icon-weather-condition-hourly-23:before {
  content: "\eafd";
}
.icon-weather-condition-hourly-24:before {
  content: "\eafe";
}
.icon-weather-condition-hourly-25:before {
  content: "\eaff";
}
.icon-weather-condition-hourly-26:before {
  content: "\eb00";
}
.icon-weather-condition-hourly-27:before {
  content: "\eb01";
}
.icon-weather-condition-hourly-28:before {
  content: "\eb02";
}
.icon-weather-condition-hourly-30:before {
  content: "\eb04";
}
.icon-weather-condition-hourly-31:before {
  content: "\eb05";
}
.icon-weather-condition-hourly-32:before {
  content: "\eb06";
}
.icon-weather-condition-hourly-33:before {
  content: "\eb07";
}
.icon-weather-condition-hourly-34:before {
  content: "\eb08";
}
.icon-weather-condition-hourly-35:before {
  content: "\eb09";
}
.icon-overview:before {
  content: "\eb19";
}
.icon-weather-condition-1:before {
  content: "\eacf";
}
.icon-weather-condition-2:before {
  content: "\ead0";
}
.icon-weather-condition-3:before {
  content: "\ead1";
}
.icon-weather-condition-4:before {
  content: "\ead2";
}
.icon-weather-condition-5:before {
  content: "\ead3";
}
.icon-weather-condition-6:before {
  content: "\ead4";
}
.icon-weather-condition-7:before {
  content: "\ead5";
}
.icon-weather-condition-8:before {
  content: "\ead6";
}
.icon-weather-condition-9:before {
  content: "\ead7";
}
.icon-weather-condition-10:before {
  content: "\ead8";
}
.icon-weather-condition-11:before {
  content: "\ead9";
}
.icon-weather-condition-12:before {
  content: "\eada";
}
.icon-weather-condition-13:before {
  content: "\eadb";
}
.icon-weather-condition-14:before {
  content: "\eadc";
}
.icon-weather-condition-15:before {
  content: "\eadd";
}
.icon-weather-condition-16:before {
  content: "\eade";
}
.icon-weather-condition-17:before {
  content: "\eadf";
}
.icon-cw-platform:before {
  content: "\eacc";
  color: #00a0be;
}
.icon-Union:before {
  content: "\eacb";
}
.icon-download:before {
  content: "\eb0e";
  color: #aab400;
}
.icon-print:before {
  content: "\eb0f";
  color: #aab400;
}
.icon-templates:before {
  content: "\eaa2";
}
.icon-alert-triangle:before {
  content: "\eb33";
  color: #fff;
}
.icon-email-glyph:before {
  content: "\eb34";
  color: #fff;
}
.icon-detect-field:before {
  content: "\eb2b";
  color: #ffca41;
}
.icon-map:before {
  content: "\eb31";
  color: #ffca41;
}
.icon-chevron-left-glyph:before {
  content: "\eae0";
}
.icon-chevron-right-glyph1:before {
  content: "\eae1";
}
.icon-undo:before {
  content: "\eae2";
  color: #00a0be;
}
.icon-farm-location:before {
  content: "\ea92";
}
.icon-cloud:before {
  content: "\e953";
}
.icon-cloud-type:before {
  content: "\eac1";
}
.icon-cloud-with-rain:before {
  content: "\eac2";
}
.icon-down-arrow-without-bg:before {
  content: "\eac3";
  color: #8e9293;
}
.icon-graph:before {
  content: "\eac4";
}
.icon-map-location:before {
  content: "\eac5";
  color: #8e9293;
}
.icon-precipitation1:before {
  content: "\eac7";
  color: #8e9293;
}
.icon-precipitation-multi:before {
  content: "\eac8";
  color: #8e9293;
}
.icon-pressure-avg:before {
  content: "\eac9";
  color: #8e9293;
}
.icon-sun-outline:before {
  content: "\eaca";
}
.icon-up-arrow-without-bg:before {
  content: "\eae3";
  color: #8e9293;
}
.icon-edit-icon:before {
  content: "\ea9f";
  color: #fff;
}
.icon-add-circle-green:before {
  content: "\eabc";
  color: #aab400;
}
.icon-add-drawing-green:before {
  content: "\eabd";
  color: #aab400;
}
.icon-bin-outline-red:before {
  content: "\eabe";
  color: #d91e3f;
}
.icon-analytics-btn:before {
  content: "\e932";
  color: #fcfcfc;
}
.icon-area:before {
  content: "\e933";
}
.icon-area-black:before {
  content: "\e934";
  color: #515253;
}
.icon-area-grey:before {
  content: "\e935";
  color: #c1c5c8;
}
.icon-area-icon:before {
  content: "\e936";
  color: #515253;
}
.icon-area-red-icon:before {
  content: "\e937";
  color: #f74141;
}
.icon-area-white-icon:before {
  content: "\e938";
  color: #c1c5c8;
}
.icon-avatar-gray .path1:before {
  content: "\e939";
  color: rgb(112, 115, 116);
}
.icon-avatar-gray .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(232, 234, 237);
}
.icon-avatar-gray .path3:before {
  content: "\e900";
  margin-left: -1em;
  color: rgb(112, 115, 116);
}
.icon-base_logo .path1:before {
  content: "\e93b";
  color: rgb(255, 255, 255);
}
.icon-base_logo .path2:before {
  content: "\e93c";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-base_logo .path3:before {
  content: "\e93d";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-base_logo .path4:before {
  content: "\e93e";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-base_logo .path5:before {
  content: "\e93f";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-base_logo .path6:before {
  content: "\e940";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-base_logo .path7:before {
  content: "\e901";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-check:before {
  content: "\e941";
  color: #aab400;
}
.icon-check-glyph:before {
  content: "\e942";
  color: #707374;
}
.icon-close:before {
  content: "\e943";
  color: #f74141;
}
.icon-close-glyph:before {
  content: "\e944";
  color: #515253;
}
.icon-company:before {
  content: "\e945";
}
.icon-create-season-crop-inactive .path1:before {
  content: "\e946";
  color: rgb(255, 255, 255);
}
.icon-create-season-crop-inactive .path2:before {
  content: "\e947";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-create-season-crop-inactive .path3:before {
  content: "\e902";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-create-season-fields-active .path1:before {
  content: "\e948";
  color: rgb(255, 255, 255);
}
.icon-create-season-fields-active .path2:before {
  content: "\e949";
  margin-left: -1.0419921875em;
  color: rgb(0, 160, 190);
}
.icon-create-season-fields-active .path3:before {
  content: "\e903";
  margin-left: -1.0419921875em;
  color: rgb(0, 160, 190);
}
.icon-create-season-fields-inactive .path1:before {
  content: "\e94a";
  color: rgb(255, 255, 255);
}
.icon-create-season-fields-inactive .path2:before {
  content: "\e94b";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-create-season-fields-inactive .path3:before {
  content: "\e904";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-create-season-name-active .path1:before {
  content: "\e94c";
  color: rgb(255, 255, 255);
}
.icon-create-season-name-active .path2:before {
  content: "\e94d";
  margin-left: -1.0419921875em;
  color: rgb(0, 160, 190);
}
.icon-create-season-name-active .path3:before {
  content: "\e905";
  margin-left: -1.0419921875em;
  color: rgb(0, 160, 190);
}
.icon-create-season-name-inactive .path1:before {
  content: "\e94e";
  color: rgb(255, 255, 255);
}
.icon-create-season-name-inactive .path2:before {
  content: "\e94f";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-create-season-name-inactive .path3:before {
  content: "\e906";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-create-seasons-seeds:before {
  content: "\e950";
  color: #ff8143;
}
.icon-create-seasons-select-fields:before {
  content: "\e951";
}
.icon-create-seasons-select-fields1:before {
  content: "\eae4";
}
.icon-create-seasons-select-fields2:before {
  content: "\eae5";
}
.icon-create-season-start-date-active .path1:before {
  content: "\e954";
  color: rgb(255, 255, 255);
}
.icon-create-season-start-date-active .path2:before {
  content: "\e955";
  margin-left: -1.0419921875em;
  color: rgb(0, 160, 190);
}
.icon-create-season-start-date-active .path3:before {
  content: "\e907";
  margin-left: -1.0419921875em;
  color: rgb(0, 160, 190);
}
.icon-create-season-start-date-inactive .path1:before {
  content: "\e956";
  color: rgb(255, 255, 255);
}
.icon-create-season-start-date-inactive .path2:before {
  content: "\e957";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-create-season-start-date-inactive .path3:before {
  content: "\e908";
  margin-left: -1.0419921875em;
  color: rgb(193, 197, 200);
}
.icon-crop-icon:before {
  content: "\e958";
  color: #515253;
}
.icon-cropwise-logo-border .path1:before {
  content: "\e927";
  color: rgb(255, 255, 255);
}
.icon-cropwise-logo-border .path2:before {
  content: "\e928";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path3:before {
  content: "\e929";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path4:before {
  content: "\e92a";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path5:before {
  content: "\e92b";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path6:before {
  content: "\e92c";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path7:before {
  content: "\e92d";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path8:before {
  content: "\e92e";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path9:before {
  content: "\e92f";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path10:before {
  content: "\e930";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-cropwise-logo-border .path11:before {
  content: "\e931";
  margin-left: -1.6435546875em;
  color: rgb(0, 160, 190);
}
.icon-default-crop:before {
  content: "\e959";
}
.icon-down-arrow .path1:before {
  content: "\e95a";
  color: rgb(242, 244, 246);
}
.icon-down-arrow .path2:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 160, 190);
}
.icon-down-arrow .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 160, 190);
}
.icon-drag-and-drop:before {
  content: "\e95c";
  color: #f9c43d;
}
.icon-edit-glyph:before {
  content: "\e95e";
  color: #515253;
}
.icon-edit-icon1:before {
  content: "\e95f";
  color: #fff;
}
.icon-empty_property_gray .path1:before {
  content: "\e960";
  color: rgb(232, 234, 237);
}
.icon-empty_property_gray .path2:before {
  content: "\e961";
  margin-left: -2.08984375em;
  color: rgb(232, 234, 237);
}
.icon-empty_property_gray .path3:before {
  content: "\e962";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path4:before {
  content: "\e963";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path5:before {
  content: "\e964";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path6:before {
  content: "\e965";
  margin-left: -2.08984375em;
  color: rgb(166, 166, 166);
}
.icon-empty_property_gray .path7:before {
  content: "\e966";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path8:before {
  content: "\e967";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path9:before {
  content: "\e968";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path10:before {
  content: "\e969";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path11:before {
  content: "\e96a";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path12:before {
  content: "\e96b";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path13:before {
  content: "\e96c";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path14:before {
  content: "\e96d";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path15:before {
  content: "\e96e";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path16:before {
  content: "\e96f";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path17:before {
  content: "\e970";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path18:before {
  content: "\e971";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path19:before {
  content: "\e972";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path20:before {
  content: "\e973";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path21:before {
  content: "\e974";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path22:before {
  content: "\e975";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path23:before {
  content: "\e976";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path24:before {
  content: "\e977";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path25:before {
  content: "\e978";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path26:before {
  content: "\e979";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path27:before {
  content: "\e97a";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path28:before {
  content: "\e97b";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path29:before {
  content: "\e97c";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path30:before {
  content: "\e97d";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path31:before {
  content: "\e97e";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path32:before {
  content: "\e97f";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path33:before {
  content: "\e980";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path34:before {
  content: "\e981";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path35:before {
  content: "\e982";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path36:before {
  content: "\e983";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path37:before {
  content: "\e984";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path38:before {
  content: "\e985";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path39:before {
  content: "\e986";
  margin-left: -2.08984375em;
  color: rgb(172, 172, 172);
}
.icon-empty_property_gray .path40:before {
  content: "\e987";
  margin-left: -2.08984375em;
  color: rgb(188, 188, 188);
}
.icon-empty_property_gray .path41:before {
  content: "\e988";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path42:before {
  content: "\e989";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path43:before {
  content: "\e98a";
  margin-left: -2.08984375em;
  color: rgb(145, 145, 145);
}
.icon-empty_property_gray .path44:before {
  content: "\e98b";
  margin-left: -2.08984375em;
  color: rgb(153, 153, 153);
}
.icon-empty_property_gray .path45:before {
  content: "\e98c";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path46:before {
  content: "\e98d";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path47:before {
  content: "\e98e";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path48:before {
  content: "\e98f";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path49:before {
  content: "\e990";
  margin-left: -2.08984375em;
  color: rgb(207, 207, 206);
}
.icon-empty_property_gray .path50:before {
  content: "\e991";
  margin-left: -2.08984375em;
  color: rgb(221, 221, 221);
}
.icon-empty_property_gray .path51:before {
  content: "\e992";
  margin-left: -2.08984375em;
  color: rgb(153, 153, 153);
}
.icon-empty_property_gray .path52:before {
  content: "\e993";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path53:before {
  content: "\e994";
  margin-left: -2.08984375em;
  color: rgb(207, 207, 206);
}
.icon-empty_property_gray .path54:before {
  content: "\e995";
  margin-left: -2.08984375em;
  color: rgb(221, 221, 221);
}
.icon-empty_property_gray .path55:before {
  content: "\e996";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path56:before {
  content: "\e997";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path57:before {
  content: "\e998";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path58:before {
  content: "\e999";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path59:before {
  content: "\e99a";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path60:before {
  content: "\e99b";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path61:before {
  content: "\e99c";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path62:before {
  content: "\e99d";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path63:before {
  content: "\e99e";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path64:before {
  content: "\e99f";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path65:before {
  content: "\e9a0";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path66:before {
  content: "\e9a1";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path67:before {
  content: "\e9a2";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path68:before {
  content: "\e9a3";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path69:before {
  content: "\e9a4";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path70:before {
  content: "\e9a5";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path71:before {
  content: "\e9a6";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path72:before {
  content: "\e9a7";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path73:before {
  content: "\e9a8";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path74:before {
  content: "\e9a9";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path75:before {
  content: "\e9aa";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path76:before {
  content: "\e9ab";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path77:before {
  content: "\e9ac";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path78:before {
  content: "\e9ad";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path79:before {
  content: "\e9ae";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path80:before {
  content: "\e9af";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path81:before {
  content: "\e9b0";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path82:before {
  content: "\e9b1";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path83:before {
  content: "\e9b2";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path84:before {
  content: "\e9b3";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path85:before {
  content: "\e9b4";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path86:before {
  content: "\e9b5";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path87:before {
  content: "\e9b6";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path88:before {
  content: "\e9b7";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path89:before {
  content: "\e9b8";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path90:before {
  content: "\e9b9";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path91:before {
  content: "\e9ba";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path92:before {
  content: "\e9bb";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path93:before {
  content: "\e9bc";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path94:before {
  content: "\e9bd";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path95:before {
  content: "\e9be";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path96:before {
  content: "\e9bf";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path97:before {
  content: "\e9c0";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path98:before {
  content: "\e9c1";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path99:before {
  content: "\e9c2";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path100:before {
  content: "\e9c3";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path101:before {
  content: "\e9c4";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path102:before {
  content: "\e9c5";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path103:before {
  content: "\e9c6";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path104:before {
  content: "\e9c7";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path105:before {
  content: "\e9c8";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path106:before {
  content: "\e9c9";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path107:before {
  content: "\e9ca";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path108:before {
  content: "\e9cb";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path109:before {
  content: "\e9cc";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path110:before {
  content: "\e9cd";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path111:before {
  content: "\e9ce";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path112:before {
  content: "\e9cf";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path113:before {
  content: "\e9d0";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path114:before {
  content: "\e9d1";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path115:before {
  content: "\e9d2";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path116:before {
  content: "\e9d3";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path117:before {
  content: "\e9d4";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path118:before {
  content: "\e9d5";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path119:before {
  content: "\e9d6";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path120:before {
  content: "\e9d7";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path121:before {
  content: "\e9d8";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path122:before {
  content: "\e9d9";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path123:before {
  content: "\e9da";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path124:before {
  content: "\e9db";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path125:before {
  content: "\e9dc";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path126:before {
  content: "\e9dd";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path127:before {
  content: "\e9de";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path128:before {
  content: "\e9df";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path129:before {
  content: "\e9e0";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path130:before {
  content: "\e9e1";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path131:before {
  content: "\e9e2";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path132:before {
  content: "\e9e3";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path133:before {
  content: "\e9e4";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path134:before {
  content: "\e9e5";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path135:before {
  content: "\e9e6";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path136:before {
  content: "\e9e7";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path137:before {
  content: "\e9e8";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path138:before {
  content: "\e9e9";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path139:before {
  content: "\e9ea";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path140:before {
  content: "\e9eb";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path141:before {
  content: "\e9ec";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path142:before {
  content: "\e9ed";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path143:before {
  content: "\e9ee";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path144:before {
  content: "\e9ef";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path145:before {
  content: "\e9f0";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path146:before {
  content: "\e9f1";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path147:before {
  content: "\e9f2";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path148:before {
  content: "\e9f3";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path149:before {
  content: "\e9f4";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path150:before {
  content: "\e9f5";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path151:before {
  content: "\e9f6";
  margin-left: -2.08984375em;
  color: rgb(177, 177, 177);
}
.icon-empty_property_gray .path152:before {
  content: "\e9f7";
  margin-left: -2.08984375em;
  color: rgb(170, 170, 170);
}
.icon-empty_property_gray .path153:before {
  content: "\e9f8";
  margin-left: -2.08984375em;
  color: rgb(181, 181, 181);
}
.icon-empty_property_gray .path154:before {
  content: "\e9f9";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path155:before {
  content: "\e9fa";
  margin-left: -2.08984375em;
  color: rgb(196, 196, 196);
}
.icon-empty_property_gray .path156:before {
  content: "\e9fb";
  margin-left: -2.08984375em;
  color: rgb(215, 215, 215);
}
.icon-empty_property_gray .path157:before {
  content: "\e9fc";
  margin-left: -2.08984375em;
  color: rgb(205, 205, 205);
}
.icon-empty_property_gray .path158:before {
  content: "\e9fd";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path159:before {
  content: "\e9fe";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path160:before {
  content: "\e9ff";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path161:before {
  content: "\ea00";
  margin-left: -2.08984375em;
  color: rgb(166, 166, 166);
}
.icon-empty_property_gray .path162:before {
  content: "\ea01";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path163:before {
  content: "\ea02";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path164:before {
  content: "\ea03";
  margin-left: -2.08984375em;
  color: rgb(173, 173, 173);
}
.icon-empty_property_gray .path165:before {
  content: "\ea04";
  margin-left: -2.08984375em;
  color: rgb(166, 166, 166);
}
.icon-EmptyState_Image_Organizations .path1:before {
  content: "\ea05";
  color: rgb(232, 234, 237);
}
.icon-EmptyState_Image_Organizations .path2:before {
  content: "\ea06";
  margin-left: -2.2265625em;
  color: rgb(232, 234, 237);
}
.icon-EmptyState_Image_Organizations .path3:before {
  content: "\ea07";
  margin-left: -2.2265625em;
  color: rgb(232, 234, 237);
}
.icon-EmptyState_Image_Organizations .path4:before {
  content: "\ea08";
  margin-left: -2.2265625em;
  color: rgb(166, 166, 166);
}
.icon-EmptyState_Image_Organizations .path5:before {
  content: "\ea09";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path6:before {
  content: "\ea0a";
  margin-left: -2.2265625em;
  color: rgb(166, 166, 166);
}
.icon-EmptyState_Image_Organizations .path7:before {
  content: "\ea0b";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path8:before {
  content: "\ea0c";
  margin-left: -2.2265625em;
  color: rgb(172, 172, 172);
}
.icon-EmptyState_Image_Organizations .path9:before {
  content: "\ea0d";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path10:before {
  content: "\ea0e";
  margin-left: -2.2265625em;
  color: rgb(172, 172, 172);
}
.icon-EmptyState_Image_Organizations .path11:before {
  content: "\ea0f";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path12:before {
  content: "\ea10";
  margin-left: -2.2265625em;
  color: rgb(172, 172, 172);
}
.icon-EmptyState_Image_Organizations .path13:before {
  content: "\ea11";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path14:before {
  content: "\ea12";
  margin-left: -2.2265625em;
  color: rgb(172, 172, 172);
}
.icon-EmptyState_Image_Organizations .path15:before {
  content: "\ea13";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path16:before {
  content: "\ea14";
  margin-left: -2.2265625em;
  color: rgb(172, 172, 172);
}
.icon-EmptyState_Image_Organizations .path17:before {
  content: "\ea15";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path18:before {
  content: "\ea16";
  margin-left: -2.2265625em;
  color: rgb(172, 172, 172);
}
.icon-EmptyState_Image_Organizations .path19:before {
  content: "\ea17";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path20:before {
  content: "\ea18";
  margin-left: -2.2265625em;
  color: rgb(172, 172, 172);
}
.icon-EmptyState_Image_Organizations .path21:before {
  content: "\ea19";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path22:before {
  content: "\ea1a";
  margin-left: -2.2265625em;
  color: rgb(215, 215, 215);
}
.icon-EmptyState_Image_Organizations .path23:before {
  content: "\ea1b";
  margin-left: -2.2265625em;
  color: rgb(215, 215, 215);
}
.icon-EmptyState_Image_Organizations .path24:before {
  content: "\ea1c";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path25:before {
  content: "\ea1d";
  margin-left: -2.2265625em;
  color: rgb(188, 188, 188);
}
.icon-EmptyState_Image_Organizations .path26:before {
  content: "\ea1e";
  margin-left: -2.2265625em;
  color: rgb(215, 215, 215);
}
.icon-EmptyState_Image_Organizations .path27:before {
  content: "\ea1f";
  margin-left: -2.2265625em;
  color: rgb(166, 166, 166);
}
.icon-EmptyState_Image_Organizations .path28:before {
  content: "\ea20";
  margin-left: -2.2265625em;
  color: rgb(173, 173, 173);
}
.icon-EmptyState_Image_Organizations .path29:before {
  content: "\ea21";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path30:before {
  content: "\ea22";
  margin-left: -2.2265625em;
  color: rgb(221, 221, 221);
}
.icon-EmptyState_Image_Organizations .path31:before {
  content: "\ea23";
  margin-left: -2.2265625em;
  color: rgb(255, 255, 255);
}
.icon-EmptyState_Image_Organizations .path32:before {
  content: "\ea24";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
  opacity: 0.3;
}
.icon-EmptyState_Image_Organizations .path33:before {
  content: "\ea25";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path34:before {
  content: "\ea26";
  margin-left: -2.2265625em;
  color: rgb(255, 255, 255);
}
.icon-EmptyState_Image_Organizations .path35:before {
  content: "\ea27";
  margin-left: -2.2265625em;
  color: rgb(242, 242, 243);
}
.icon-EmptyState_Image_Organizations .path36:before {
  content: "\ea28";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path37:before {
  content: "\ea29";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path38:before {
  content: "\ea2a";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path39:before {
  content: "\ea2b";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path40:before {
  content: "\ea2c";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path41:before {
  content: "\ea2d";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path42:before {
  content: "\ea2e";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path43:before {
  content: "\ea2f";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path44:before {
  content: "\ea30";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path45:before {
  content: "\ea31";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path46:before {
  content: "\ea32";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path47:before {
  content: "\ea33";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path48:before {
  content: "\ea34";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path49:before {
  content: "\ea35";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path50:before {
  content: "\ea36";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path51:before {
  content: "\ea37";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
  opacity: 0.3;
}
.icon-EmptyState_Image_Organizations .path52:before {
  content: "\ea38";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
  opacity: 0.3;
}
.icon-EmptyState_Image_Organizations .path53:before {
  content: "\ea39";
  margin-left: -2.2265625em;
  color: rgb(255, 255, 255);
}
.icon-EmptyState_Image_Organizations .path54:before {
  content: "\ea3a";
  margin-left: -2.2265625em;
  color: rgb(255, 255, 255);
}
.icon-EmptyState_Image_Organizations .path55:before {
  content: "\ea3b";
  margin-left: -2.2265625em;
  color: rgb(255, 255, 255);
}
.icon-EmptyState_Image_Organizations .path56:before {
  content: "\ea3c";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path57:before {
  content: "\ea3d";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path58:before {
  content: "\ea3e";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path59:before {
  content: "\ea3f";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path60:before {
  content: "\ea40";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path61:before {
  content: "\ea41";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path62:before {
  content: "\ea42";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path63:before {
  content: "\ea43";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path64:before {
  content: "\ea44";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path65:before {
  content: "\ea45";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path66:before {
  content: "\ea46";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path67:before {
  content: "\ea47";
  margin-left: -2.2265625em;
  color: rgb(211, 211, 211);
}
.icon-EmptyState_Image_Organizations .path68:before {
  content: "\ea48";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path69:before {
  content: "\ea49";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path70:before {
  content: "\ea4a";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path71:before {
  content: "\ea4b";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path72:before {
  content: "\ea4c";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path73:before {
  content: "\ea4d";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path74:before {
  content: "\ea4e";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path75:before {
  content: "\ea4f";
  margin-left: -2.2265625em;
  color: rgb(215, 215, 215);
}
.icon-EmptyState_Image_Organizations .path76:before {
  content: "\ea50";
  margin-left: -2.2265625em;
  color: rgb(166, 166, 166);
}
.icon-EmptyState_Image_Organizations .path77:before {
  content: "\ea51";
  margin-left: -2.2265625em;
  color: rgb(153, 153, 153);
}
.icon-EmptyState_Image_Organizations .path78:before {
  content: "\ea52";
  margin-left: -2.2265625em;
  color: rgb(153, 153, 153);
}
.icon-EmptyState_Image_Organizations .path79:before {
  content: "\ea53";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path80:before {
  content: "\ea54";
  margin-left: -2.2265625em;
  color: rgb(232, 234, 237);
}
.icon-EmptyState_Image_Organizations .path81:before {
  content: "\ea55";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path82:before {
  content: "\ea56";
  margin-left: -2.2265625em;
  color: rgb(193, 197, 200);
}
.icon-EmptyState_Image_Organizations .path83:before {
  content: "\ea57";
  margin-left: -2.2265625em;
  color: rgb(142, 146, 147);
}
.icon-EmptyState_Image_Organizations .path84:before {
  content: "\ea58";
  margin-left: -2.2265625em;
  color: rgb(232, 234, 237);
}
.icon-EmptyState_Image_Organizations .path85:before {
  content: "\ea59";
  margin-left: -2.2265625em;
  color: rgb(221, 221, 221);
}
.icon-files:before {
  content: "\ea5a";
  color: #f9c43d;
}
.icon-files-white:before {
  content: "\ea5b";
  color: #fff;
}
.icon-find-file:before {
  content: "\ea5c";
  color: #f9c43d;
}
.icon-harvest-icon .path1:before {
  content: "\ea5d";
  color: rgb(242, 244, 246);
}
.icon-harvest-icon .path2:before {
  content: "\ea5e";
  margin-left: -0.9482421875em;
  color: rgb(242, 244, 246);
}
.icon-harvest-icon .path3:before {
  content: "\ea5f";
  margin-left: -0.9482421875em;
  color: rgb(242, 244, 246);
}
.icon-harvest-icon .path4:before {
  content: "\ea60";
  margin-left: -0.9482421875em;
  color: rgb(142, 146, 147);
}
.icon-harvest-icon .path5:before {
  content: "\ea61";
  margin-left: -0.9482421875em;
  color: rgb(142, 146, 147);
}
.icon-harvest-icon .path6:before {
  content: "\ea62";
  margin-left: -0.9482421875em;
  color: rgb(142, 146, 147);
}
.icon-header-expand .path1:before {
  content: "\ea69";
  color: rgb(0, 160, 190);
}
.icon-header-expand .path2:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-help-icon:before {
  content: "\ea6b";
  color: #707374;
}
.icon-hover .path1:before {
  content: "\ea6c";
  color: rgb(170, 180, 0);
}
.icon-hover .path2:before {
  content: "\ea6d";
  margin-left: -1.2001953125em;
  color: rgb(255, 255, 255);
}
.icon-icon_noboundary:before {
  content: "\ea6e";
  color: #f9c43d;
}
.icon-icon_noboundary_hover .path1:before {
  content: "\ea6f";
  color: rgb(249, 196, 61);
}
.icon-icon_noboundary_hover .path2:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon_noboundary_hover .path3:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Icon_NoProperty .path1:before {
  content: "\ea72";
  color: rgb(232, 234, 237);
}
.icon-Icon_NoProperty .path2:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(245, 248, 252);
}
.icon-Icon_NoProperty .path3:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(245, 248, 252);
}
.icon-Icon_NoProperty .path4:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoProperty .path5:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoProperty .path6:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoProperty .path7:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoSeason .path1:before {
  content: "\ea79";
  color: rgb(232, 234, 237);
}
.icon-Icon_NoSeason .path2:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(232, 234, 237);
}
.icon-Icon_NoSeason .path3:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(232, 234, 237);
}
.icon-Icon_NoSeason .path4:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(232, 234, 237);
}
.icon-Icon_NoSeason .path5:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(232, 234, 237);
}
.icon-Icon_NoSeason .path6:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(232, 234, 237);
}
.icon-Icon_NoSeason .path7:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(232, 234, 237);
}
.icon-Icon_NoSeason .path8:before {
  content: "\ea80";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoSeason .path9:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoSeason .path10:before {
  content: "\ea82";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoSeason .path11:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-Icon_NoSeason .path12:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-icon_withboundary:before {
  content: "\ea85";
}
.icon-menu-continue:before {
  content: "\ea86";
  color: #f0f3f7;
}
.icon-menu-end:before {
  content: "\ea87";
  color: #f0f3f7;
}
.icon-menu-end-inactive:before {
  content: "\ea88";
  color: #f0f3f7;
}
.icon-menu-expand:before {
  content: "\ea89";
  color: #f0f3f7;
}
.icon-menu-start:before {
  content: "\ea8a";
  color: #f0f3f7;
}
.icon-menu-start-change:before {
  content: "\ea8b";
  color: #f0f3f7;
}
.icon-menu-start-change-inactive:before {
  content: "\ea8c";
  color: #f0f3f7;
}
.icon-menu-start-inactive:before {
  content: "\ea8d";
  color: #f0f3f7;
}
.icon-new-area:before {
  content: "\ea8e";
  color: #f9c43d;
}
.icon-new-area-white:before {
  content: "\ea8f";
  color: #fff;
}
.icon-no-area-icon:before {
  content: "\ea90";
  color: #8e9293;
}
.icon-no-crop-icon:before {
  content: "\ea91";
  color: #8e9293;
}
.icon-organization-tab:before {
  content: "\ea93";
  color: #fefefe;
}
.icon-organization-tab-active .path1:before {
  content: "\ea94";
  color: rgb(245, 248, 252);
}
.icon-organization-tab-active .path2:before {
  content: "\eae6";
  margin-left: -1em;
  color: rgb(0, 160, 190);
}
.icon-planting-icon .path1:before {
  content: "\ea96";
  color: rgb(242, 244, 246);
}
.icon-planting-icon .path2:before {
  content: "\ea97";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-planting-icon .path3:before {
  content: "\ea98";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-planting-icon .path4:before {
  content: "\ea99";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-planting-icon .path5:before {
  content: "\ea9a";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-planting-icon .path6:before {
  content: "\ea9b";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-planting-icon .path7:before {
  content: "\ea9c";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-planting-icon .path8:before {
  content: "\ea9d";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-planting-icon .path9:before {
  content: "\ea9e";
  margin-left: -0.87109375em;
  color: rgb(142, 146, 147);
}
.icon-plus:before {
  content: "\eaa0";
  color: #f9c43d;
}
.icon-plus-white:before {
  content: "\eaa1";
  color: #fff;
}
.icon-property-icon:before {
  content: "\eaa3";
  color: #515253;
}
.icon-Rectangle311:before {
  content: "\eaa4";
  color: #e8eaed;
}
.icon-Rectangle-white:before {
  content: "\eaa5";
  color: #e8eaed;
}
.icon-seeds-glyph:before {
  content: "\eaa6";
  color: #515253;
}
.icon-seeds-glyph-orange:before {
  content: "\eaa7";
  color: #ff8143;
}
.icon-signin-rect:before {
  content: "\e926";
  color: #aab400;
}
.icon-soybean-icon .path1:before {
  content: "\eaa8";
  color: rgb(255, 255, 255);
}
.icon-soybean-icon .path2:before {
  content: "\eb32";
  margin-left: -1em;
  color: rgb(0, 181, 216);
}
.icon-syngenta_logo .path1:before {
  content: "\eaa9";
  color: rgb(0, 146, 64);
}
.icon-syngenta_logo .path2:before {
  content: "\eaaa";
  margin-left: -2.3818359375em;
  color: rgb(27, 65, 142);
}
.icon-syngenta_logo .path3:before {
  content: "\eaab";
  margin-left: -2.3818359375em;
  color: rgb(112, 115, 116);
}
.icon-syngenta_logo .path4:before {
  content: "\eaac";
  margin-left: -2.3818359375em;
  color: rgb(112, 115, 116);
}
.icon-syngenta_logo .path5:before {
  content: "\eaad";
  margin-left: -2.3818359375em;
  color: rgb(112, 115, 116);
}
.icon-syngenta_logo .path6:before {
  content: "\eaae";
  margin-left: -2.3818359375em;
  color: rgb(112, 115, 116);
}
.icon-syngenta_logo .path7:before {
  content: "\eaaf";
  margin-left: -2.3818359375em;
  color: rgb(112, 115, 116);
}
.icon-syngenta_logo .path8:before {
  content: "\eab0";
  margin-left: -2.3818359375em;
  color: rgb(112, 115, 116);
}
.icon-syngenta_logo .path9:before {
  content: "\eab1";
  margin-left: -2.3818359375em;
  color: rgb(112, 115, 116);
}
.icon-team-members:before {
  content: "\eab2";
  color: #707374;
}
.icon-timeline-point .path1:before {
  content: "\eab3";
  color: rgb(0, 0, 0);
  opacity: 0.25;
}
.icon-timeline-point .path2:before {
  content: "\eab4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-timeline-point .path3:before {
  content: "\eab5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-tree-selection:before {
  content: "\eab6";
  color: #f9c43d;
}
.icon-tree-selection-white:before {
  content: "\eab7";
  color: #fff;
}
.icon-up-arrow .path1:before {
  content: "\eab8";
  color: rgb(242, 244, 246);
}
.icon-up-arrow .path2:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(0, 160, 190);
}
.icon-user:before {
  content: "\eaba";
  color: #707374;
}
.icon-winner:before {
  content: "\eabb";
  color: #aab400;
}
.icon-addCircleDrawing:before {
  content: "\e91d";
  color: #f9c43d;
}
.icon-addDrawing:before {
  content: "\e91e";
  color: #f9c43d;
}
.icon-bell-outline:before {
  content: "\e91f";
  color: #707374;
}
.icon-bin-outline:before {
  content: "\e920";
  color: #515253;
}
.icon-subtractCircleDrawing:before {
  content: "\e921";
  color: #eb8205;
}
.icon-subtractPolygonDrawing:before {
  content: "\e922";
  color: #eb8205;
}
.icon-team-members1:before {
  content: "\e923";
  color: #707374;
}
.icon-undo1:before {
  content: "\e924";
  color: #00a0be;
}
.icon-user1:before {
  content: "\e925";
  color: #707374;
}
.icon-acerolacherry:before {
  content: "\e90a";
  color: #515253;
}
.icon-alfalfa:before {
  content: "\e90b";
  color: #515253;
}
.icon-apple:before {
  content: "\e90c";
  color: #515253;
}
.icon-atemoya:before {
  content: "\e90d";
  color: #515253;
}
.icon-avocado:before {
  content: "\e90e";
  color: #515253;
}
.icon-banana:before {
  content: "\e90f";
  color: #515253;
}
.icon-barley:before {
  content: "\e910";
  color: #515253;
}
.icon-bean:before {
  content: "\e911";
  color: #515253;
}
.icon-blueberry:before {
  content: "\e912";
  color: #515253;
}
.icon-brachiaria:before {
  content: "\e913";
  color: #515253;
}
.icon-cherry:before {
  content: "\e914";
  color: #515253;
}
.icon-coffee-bean:before {
  content: "\e915";
  color: #515253;
}
.icon-corn:before {
  content: "\e916";
  color: #515253;
}
.icon-cotton:before {
  content: "\e917";
  color: #515253;
}
.icon-create-season-name-inactive1 .path1:before {
  content: "\e918";
  color: rgb(255, 255, 255);
}
.icon-create-season-name-inactive1 .path2:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-create-season-name-inactive1 .path3:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(193, 197, 200);
}
.icon-cropseason:before {
  content: "\e919";
  color: #515253;
}
.icon-lemon:before {
  content: "\e91a";
  color: #515253;
}
.icon-onion:before {
  content: "\e91b";
  color: #515253;
}
.icon-orange:before {
  content: "\e91c";
  color: #515253;
}
.icon-rice:before {
  content: "\e95d";
  color: #515253;
}
.icon-soybeans:before {
  content: "\ea63";
  color: #515253;
}
.icon-sugarcane:before {
  content: "\ea64";
  color: #515253;
}
.icon-sunflower:before {
  content: "\ea65";
  color: #515253;
}
.icon-tomato:before {
  content: "\ea66";
  color: #515253;
}
.icon-turnip:before {
  content: "\ea67";
  color: #515253;
}
.icon-watermelon:before {
  content: "\ea68";
  color: #515253;
}
.icon-wheat:before {
  content: "\ea95";
  color: #515253;
}
.icon-user-outline:before {
  content: "\eb2d";
}
.icon-union-1:before {
  content: "\eb2c";
}
.icon-map1:before {
  content: "\eb6c";
}

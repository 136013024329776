.button-cont {
  display: flex;
  gap: 4px;
  text-align: end;
  position: absolute;
  height: 72px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 16px 30px;
  background: white;
}

.additional-info {
  background-color: #f3f4f6;
  color: #696f88;
  font-style: SemiBold;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  letter-spacing: -0.75%;
  margin: 24px -24px;
  padding: 6px;
}

.drawer .ant-drawer-title {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  color: #14151c;
}

.drawer .ant-drawer-body {
  padding: 0px;
}

.crop-cycle-cont {
  padding: 12px 24px 0px 24px;
}

.crop-cycle-cont .crop-cycle-header-container {
  background: #f3f4f6;
  height: 52px;
  align-items: center;
  padding: 16px;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #14151c;
}

.selected-area-container {
  height: 48px;
  background: #f3f4f6;
  display: flex;
  align-items: center;
  padding-left: 19px;

  .single-field-info-container {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow-wrap: anywhere
  }
}

.selected-area-container .selected-area {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #696f88;
  padding-left: 12px;
  width: 100%;
}

.selected-area-container .see-more {
  float: right;
  padding-right: 16px;
}

.selected-area-container .see-more-icon {
  margin-right: 9px;
}

.selected-area-container .see-more-text {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.0075em;
  color: #0071cd;
  cursor: pointer;
}

.date-picker {
  width: 100%;
  height: 40px;
  background: #f3f4f6;
  border: 1px solid #c2c7d0;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none;

  &:hover {
    background: white;
    border: 1px solid #0092e4 !important;
    box-shadow: 0px 0px 0px 2px rgba(0, 146, 228, 0.25) !important;
  }
}

.date-picker .ant-picker-input>input {
  color: #14151c;
}

.label {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #14151c;
}

.checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #0071cd;
  border: 1px solid #0071cd;
  box-sizing: border-box;
  border-radius: 2px;

  &:hover {
    .checkbox .ant-checkbox .ant-checkbox-inner {
      border-color: #0071cd;
    }
  }
}

.attention-container {
  height: 152px;
  background: #fff3dd;
}

.attention-container .attention-title {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  color: #14151c;
}

.attention-container .attention-message {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #696f88;
  padding-left: 54px;
}

.add-crop-cycle-input {
  height: 32px;
  width: 250px;
  border-radius: 4px;
  border: 1px solid #c2c7d0;
  background-color: #f3f4f6;
  color: #696f88;
  padding: 5px 12px;
  cursor: pointer;
}

.add-crop-cycle-inpt-btn {
  border: none;
  background: none;
  box-shadow: none;

  &:hover {
    border: none;
    background: none;
    box-shadow: none;
  }

  &:focus {
    border: none;
    background: none;
    box-shadow: none;
  }

  &:disabled {
    border: none;
    background: none;
    box-shadow: none;
  }

  &:disabled:hover {
    border: none;
    background: none;
    box-shadow: none;
  }
}

.add-crop-cycle-inpt-div {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 11px;
  background: white;
  align-items: center;
}

.planting-harvesting-label {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #696f88;
  margin-left: 8px;
}

.date-range-input {
  width: 100%;
  background-color: #f3f4f6;
  border: 1px solid #c2c7d0;
  height: 38px;
}

#container {
  padding: 24px;
  overflow: auto;
}

#container .input .ant-select-selector {
  background: #f3f4f6;
  border: 1px solid #c2c7d0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #14151c;
}

#container .syt-input-number {
  background: #f3f4f6;
  border: 1px solid #c2c7d0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #14151c;
}

#container .form-container {
  margin-bottom: 18px;
}

#container .opened-dropdown-state .ant-select-selection-item {
  color: #14151c;
}

#container .form-container>.ant-form-item-control>.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 0px;
}

#container .crop-cycle-label {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.0075em;
  color: #868ca2;
}

#container .input>.ant-select-selector>.ant-select-selection-item>.ant-select-selection-item-content>div>span {
  display: none;
}

#container .input>.ant-select-selector>.ant-select-selection-item>.ant-select-selection-item-remove {
  display: none;
}

#container .edit-crop-cycle-label label {
  width: 100%;
}

#container .edit-crop-cycle-label label.ant-checkbox-wrapper {
  width: auto;
}

#container .edit-crop-cycle-label label .edit-crop-cycle-label-icon {
  margin-left: 5px;
}

.associate-crop-cycle-modal {
  cursor: url('./../../../public/drag-cursor.svg'), auto;
  pointer-events: all;

  button.ant-modal-close {
    z-index: 100000;

    .ant-modal-close-x {
      height: 44px;
      line-height: 44px;
    }
  }

  .ant-modal-header {
    border-bottom: none;
    padding: 0;
    border-radius: 4px !important;

    .ant-modal-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #14151C;

      >span {
        display: flex;
        width: 100%;

        .edit-crop-cycle-label,
        .edit-season-modal-title {
          width: 100%;
          padding: 12px 24px;
          z-index: 999;

          .label {
            font-weight: 600;
          }

          .edit-crop-cycle-label-icon {
            margin-left: 5px;
            color: #696F88;

            svg {
              color: #696F88;
            }
          }
        }
      }

      .optional-text {
        font-weight: 400;
        margin-right: 5px;
      }
    }

    .ant-modal-close-x {
      height: 44px;
      line-height: 44px;
    }
  }

  .ant-modal-body {
    padding: 0;
    border-radius: 4px;

    .page-container {
      border-radius: 4px;

      form {
        padding-bottom: 64px;
        border-radius: 4px;
        max-height: 380px;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        >div .form-container:last-child {
          margin-bottom: 0px !important;
        }

        .ant-select-item-group {
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
        }

        .custom-range-input-container .date-range-input {
          padding: 4px 20px 4px 11px;

          input {
            text-align: center;
          }
        }

        .button-cont {
          display: flex;
          gap: 4px;
          box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
          padding: 16px 24px;
          height: auto;
          border-radius: 4px;

          .defaultButton {
            margin-right: 8px;
            border-radius: 4px;
            border: 1px solid #C2C7D0;
            background: none !important;
            border: none !important;
            margin: 0 auto 0 0;
            padding: 0;
          }

          .delete-button {
            background: none !important;
            border-color: #CF3537;
            padding: 4px 9px;

            >span:last-child {
              margin: 0 !important;
            }

            &:hover {
              background: none !important;
              border-color: #CF3537;
            }
          }
        }

        .select-verieties-dropdown {
          .ant-select-selector {
            height: 100%;
          }

          .ant-select-selection-item-content {
            .associate-cropcycle-checkbox-placeholder {
              display: none;
            }
          }
        }
      }
    }

    .ant-select-selection-search {
      input {
        background: none !important;
      }
    }

    .label {
      color: #696F88;
    }
  }
}

.edit-crop-cycles-modal {
  .ant-modal-body {
    .page-container {
      form {
        padding-bottom: 32px;

        .button-cont {
          display: flex;
          gap: 4px;
          box-shadow: none;
          padding-top: 0;
          padding-bottom: 12px;
        }
      }
    }
  }
}

.dropdown-optgroup-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.ant-select-item-option-grouped {
  padding-left: 12px;
}

.dropdown-optgroup-title-divider {
  margin: 15px 0px;
}

.drag-to-move-tooltip {
  transition: opacity 2s ease-in-out;
}

.associate-cropcycle-dropdown-checkbox {
  pointer-events: all;
  margin-right: 12px;
}

.crop-date-remove-button {
  span {
    border: 1px solid #CF3537;
    padding: 7px;
    border-radius: 4px;
  }
}
.sort-icon-up-down {
  display: inline-block;
  margin-left: 6px;
  position: relative;
  cursor: pointer;

  .up-span {
      position: absolute;
      bottom: 1px;
  }

  .down-span {
      position: absolute;
      top: -12px;
  }
}

.sort-icon-container{
  .up{
    margin-bottom: -5px;
  }
  .down{
    margin-top: -10px;
  }
}